import styled, {css} from "styled-components";
import {isMobile} from "react-device-detect";

export const StyledSidebarView = styled.div`
  height: 100%;
  width: fit-content;
  padding: 12px;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgb(203, 212, 219);

  ${isMobile && css`
    height: 60px;
    width: 100%;
    padding: 10px;
    flex-direction: row;
    justify-content: space-around;
  `}
`;