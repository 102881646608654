import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { useCurrentUser } from "../../hooks/users";
import { useBoards } from "../../hooks/boards";
import { useOutsideClick } from "../../hooks/utils";

import BoardCreator from "../BoardCreator";
import styled, {css} from "styled-components";
import {isMobile} from "react-device-detect";


const StyledBoard = styled.div`
    font-family: Sen;
    font-weight: 500;
    background: white;
    padding: 12px;
    width: 200px;
    height: fit-content;
    margin: 10px;
    border-radius: 2px;
    border: 1px solid #ddd;
    
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    ${isMobile && css`
        width: calc(100% - 24px);
    `}
`

const StyledBoards = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex-flow: row wrap;
    justify-content: center;
`;

const StyledLink = styled(Link)`
    width: ${isMobile ? "100%" : "fit-content"};
`;

export const BoardsView = () => {
    const [showBoardCreator, setShowBoardCreator] = useState(false);
    const [user, userData, userId] = useCurrentUser();
    const boards = useBoards(user);
    const boardCreatorRef = useRef(null);
    let boardSections = null;
    let outsideClick = useOutsideClick(boardCreatorRef);
    const [search, setSearch] = useState("");

    if (boards) {
        /* TODO: Move this out into a new functional component*/
        boardSections = 
            <StyledBoards>
                {
                    boards.filter(board => search.trim() === "" || board.name.toLowerCase().includes(search.trim().toLowerCase())).map(board => {
                        return (
                            <StyledLink to={`/app/boards/${board.id}`} onClick={() => {
                                user.recentBoards.addFromBoardData(board);
                            }}>
                                <StyledBoard key={board.id}>
                                    {board.name}
                                    {/* {board.description && <div style={{fontFamily: "Sen"}}>{board.description}</div>} */}
                                </StyledBoard>
                            </StyledLink>
                        )
                    })
                }
            </StyledBoards>

    }

    useEffect(() => {
        if (showBoardCreator && outsideClick) {
            setShowBoardCreator(false);
        }
    }, [outsideClick]);

    const toggleAddBoard = (event) => {
        event.preventDefault();
        setShowBoardCreator(true);
    }

    return (
        <div>
            <div style={{height: 48, background: "#32308A", alignItems: "center", display: "flex", color: "white", padding: "0px 10px"}}>
                <div style={{fontFamily: "Sen", fontWeight: 600}}>B.</div>
                <div style={{flexGrow: 1}}/>
                <div className="material-icons" onClick={() => setShowBoardCreator(true)} style={{cursor: "pointer"}}>add</div>
            </div>
            {showBoardCreator && <BoardCreator reference={boardCreatorRef} />}
            <BoardSearchStyle placeholder="Looking for something?" onChange={e => setSearch(e.target.value)}/>
            {boardSections ? boardSections : <p>No boards available.</p>}
        </div>
    );
}

const BoardSearchStyle = styled.input`
    width: 500px;
    margin: 32px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    background: #eee;
    border: none;

    transition: all 0.2s;
    border-radius: 4px;

    font-family: Sen;
    font-size: 16px;
    text-align: center;
    box-sizing: border-box;

    &:focus {
        background: white;
        border: 1px solid #ddd;
        box-shadow: 0px 2px 2px rgba(20, 20, 20, 0.2);
        outline: none;
    }

    ${isMobile && css`
        width: calc(100% - 32px);
        margin: 16px;
    `}
`