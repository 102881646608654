import styled, {css} from "styled-components";

export const StyledDocumentView = styled.iframe`
  width: 100%;
  border: none;
  border-radius: 1px;
  // box-shadow: ${props => props.theme.fg.shadow.y(1)};
  ${props => props.expanded && css`
    height: 0;
    flex-grow: 1;
  `}
`;