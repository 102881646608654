import React from 'react';
import { StyledMetaSectionView, StyledMetaSectionTitle, StyledMetaSectionContent } from './StyledMetaSectionView';

interface MetaSectionViewProps {
  title: string;
  children: React.ReactNode;
}

export const MetaSectionView = (props: MetaSectionViewProps) => {
  return (
    <StyledMetaSectionView>
      <StyledMetaSectionTitle>{props.title}</StyledMetaSectionTitle>
      <StyledMetaSectionContent>
        {props.children}
      </StyledMetaSectionContent>
    </StyledMetaSectionView>
  );
};
