import styled, {css} from "styled-components";
import {isMobile} from "react-device-detect";

export const StyledMainPanelView = styled.div`
  width: 0;
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
  overflow: auto;

  ${props => props.hasDrag && css`
    filter: blur(2px);
  `}

  ${isMobile && css`
    width: 100%;
  `}
`;

export const StyledMainPanelContent = styled.div`
  width: 100%;
  height: 0;
  flex-grow: 1;
`;