import { BaseData } from "./data";
import { BaseDocument } from "./document";

/**
 * In Firebase, many different things can be 'gotten' or 'listened' to.
 * Specifically, docs, collections, and queries (which are kind of like a read only collection.)
 * 
 * We don't want to rewrite this similar logic for all, so it's contained in a Gettable abstract class.
*/

/**
 * This is the most abstract level of gettable objects,
 * anything at all that can be 'gotten'. 
 * 
 * Every gettable object will have a different structure, a type of Data (T)
 */
export abstract class Gettable<T extends BaseData> {
  /** The firebase reference this refers to. */
  abstract ref: GettableType;

  /** Performs a one-time read. */
  async get(): Promise<T | T[]> {
    const snapshot = await this.ref.get();
    // Different types of objects require different snapshot -> data conversion.
    // This leaves it generic.
    return this.snapshotToData(snapshot);
  }

  /**
   * Creates an ongoing listener for changes to an object.
   * @return {CancelListener} Call this method to cancel the snapshot listener, preventing memory leaks.
  */
  listen(observer: (data: T | T[]) => void): CancelListener {
    //@ts-ignore
    return this.ref.onSnapshot((snapshot) => {
      observer(this.snapshotToData(snapshot));
    });
  }

  /** Each object has a different system to convert snapshots into 'dumb' data. */
  abstract snapshotToData(
    snapshot:
      | firebase.firestore.DocumentSnapshot
      | firebase.firestore.QuerySnapshot
  ): T | T[];
}

/** Gettable abstracts over these firebase types. */
type GettableType =
  firebase.firestore.CollectionReference |
  firebase.firestore.DocumentReference |
  firebase.firestore.Query;

/** 
 * Some gettables are 'plural', and happen to share the same snapshot structure. 
 * As such, it's cleaner to extract this logic into a special gettable.
 */
export abstract class GettablePlural<T extends BaseData> extends Gettable<T> {
  /** Further constrain that ref is specifically a plural firebase type. */
  abstract ref: GettablePluralType;

  snapshotToData(snapshot: firebase.firestore.QuerySnapshot): T[] {
    return snapshot.docs.map(doc => new BaseDocument(doc.ref).snapshotToData(doc) as T);
  }

   /** 
   * Data is returned as an array, but it's often desired to have an object to allow O(1) recall.
   * This is common enough to deserve its own method.
   */
  static indexById<T extends BaseData>(datas: T[]): { [key: string]: T } {
    const byId: { [key: string]: T } = {};
    for (let data of datas) {
      byId[data.id] = data;
    }
    return byId;
  }
}

/** Only these firebase types are both gettable *and* contain multiple entities. */
type GettablePluralType =
  firebase.firestore.CollectionReference |
  firebase.firestore.Query;

export type CancelListener = () => void;
