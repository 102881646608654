import { UserData } from "../../users";
import { BaseComponent, BaseComponentData, Components } from "./base";


export interface DrawingComponentData extends BaseComponentData {
  data: {
    drawing: string
  }
}

export class DrawingComponent extends BaseComponent<DrawingComponentData> {
  constructor(id: string, components: Components<DrawingComponentData>) {
    super(id, components);
  }

  updateDrawing(drawing: string) {
    return this.update({
      data: { drawing },
    });
  }

  static getDefaultData(userData: UserData, parent: string, data: any = {}): Omit<DrawingComponentData, "id" | "ref"> {
    return {
      ...super.getDefaultData(userData, parent),
      componentType: "drawing",
      data: {
        drawing: "",
        ...data
      }
    };
  }
}
