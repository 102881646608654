import { auth } from "./firebase";

import { Boards } from "./boards";
import { Users } from "./users";

/**
 * Provides a singleton access to the boardwalk API.
 * Using this system lets us implicitly create a hierarchical, easy to use API.
*/
export class Boardwalk {
  /** Anything related to boards, e.g creating, adding components, etc. */
  public boards: Boards = new Boards();
  /** Anything related to a specific user, e.g updating data */
  public users: Users = new Users();

  /** 
   * An abstraction over firebase auth.
   * Moving it to the Boardwalk obj just makes it more portable.
   */
  public auth = auth;
}

export default new Boardwalk();
