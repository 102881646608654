import { UserData } from "../../users";
import { BaseComponent, BaseComponentData, Components } from "./base";


export interface TextComponentData extends BaseComponentData {
  text?: string;
}

export class TextComponent extends BaseComponent<TextComponentData> {
  constructor(id: string, components: Components<TextComponentData>) {
    super(id, components);
  }

  updateText(text: string) {
    return this.update({
      data: { text },
    });
  }

  static getDefaultData(userData: UserData, parent: string, data: any = {}): Omit<TextComponentData, "id" | "ref"> {
    return {
      ...super.getDefaultData(userData, parent),
      componentType: "text",
      data: {
        text: "",
        ...data
      }
    };
  }
}
