import React from 'react';
import { BaseComponent, BaseComponentData } from '../../../../../../api/src/boards/components/base';
import { Board } from '../../../../../../api/src/boards';
import { useComponents } from '../../../../hooks/components';
import styled from 'styled-components';
import { StyledMetaSectionItem } from '../section/StyledMetaSectionView';
import {withRouter} from "react-router-dom";
import qs from "qs";

export interface TopicsListProps {
  board: Board;
  location: Location;
  history: History;
}

export const TopicsList = withRouter((props: TopicsListProps) => {
  const components: BaseComponentData[] = useComponents(props.board);
  const topics = new Set();
  for (let component of components) {
    for (let tag of component.tags) {
      topics.add(tag);
    }
  }

  const search = qs.parse(props.location.search, {ignoreQueryPrefix: true});
  const searchTopics = new Set(search.topics)
  return (
    <div>
      {
        [...topics].map(topic => {
          const topicSelected = searchTopics.has(topic);
          return (
            <TopicsListItem
              selected={topicSelected}
              selectedColor={props.board.lastData().secondaryColor}
              onClick={(e) => {
                if (!e.shiftKey) {
                  searchTopics.clear();
                }
                if (topicSelected) {
                  searchTopics.delete(topic);
                } else {
                  searchTopics.add(topic);
                }
                const newSearch = {...search, topics: [...searchTopics]};
                const newUrl = `${location.pathname}?${qs.stringify(newSearch)}`;
                //@ts-ignore
                props.history.push(newUrl)
              }}
            >{topic}<div className="hoverpane"/></TopicsListItem>
          )
        })
      }
    </div>
  )
});

export const TopicsListItem = styled(StyledMetaSectionItem)`
  ::before {
    content: "# ";
  }
`;