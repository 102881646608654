import { useState, useRef, useEffect, useCallback, useLayoutEffect } from "react";

/**
 * Watches for clicks outside of an element.
 */
export const useOutsideClick = (ref) => {
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        const onOutsideClick = (event) => {
            ref.current && !ref.current.contains(event.target) ? 
                setClicked(true) : setClicked(false);
        }

        document.addEventListener("mousedown", onOutsideClick);
        return () => document.removeEventListener("mousedown", onOutsideClick);
    }, [ref]);
    
    return clicked;
}

/**
 * Useful for effects that set state based on async function.
 * Using this will prevent memory leaks on an unmounted component.
 * @param func effect function
 * @param deps dependency array
 */
export const useAsyncEffect = <T>(func, deps, defaultState): T => {
    const [state, setState] = useState<T>(defaultState);
    const mounted = useRef(true);

    const promise = (typeof func === 'function') ?
        func() : func;

    useEffect(() => {
        promise
            .then(res => mounted.current ? setState(res) : null);

        return () => mounted.current = false;
    }, deps);

    return state;
}

/**
 * Effect that will only be executed after component has mounted
 * @param func effect function
 * @param deps dependency array
 */
export const useMountedEffect = (func, deps) => {
    const mounted = useRef(false);

    useEffect(() => {
        if (mounted.current) {
            func();
        } else {
            mounted.current = true;
        }
    }, deps);
}

/**
 * Check for whether a component is mounted or not. Prevents state updates
 * on components that are unmounted.
 */
export const useIsMountedRef = () => {
    const isMountedRef = useRef(null);

    useEffect(() => {
        isMountedRef.current = true;
        return () => isMountedRef.current = false;
    });

    return isMountedRef;
}