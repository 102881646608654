import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

export const StyledEmbeddedComponentView = styled.div`
  border-radius: 2px;
  background: white;
  // box-shadow: inset 0px -1px 1px 0px rgba(0,0,0,0.15), 0px 1px 2px 0px rgba(0,0,0,0.33);
  border: 1px solid rgb(203, 212, 219);

  display: flex;
  flex-direction: row;
  padding: 8px;
  min-width: 150px;
`;

export const StyledEmbeddedComponentViewHeader = styled.div`
  width: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledTitleInput = styled(TextareaAutosize)`
  width: 100%;
  box-sizing: border-box;
  font-family: ${props => props.theme.brand.primaryFont.family};
  border: none;
  resize: none;
`;

export const StyledEmbeddedComponentViewContent = styled.div`
  width: fit-content;
  flex-shrink: 0;
`;