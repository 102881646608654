import * as React from "react";
import styled, { ThemeProvider } from "styled-components";
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    Switch
} from "react-router-dom";

import CreateGlobalStyle from "./styles/global";
import {THEME} from "./styles/theme";

import {
    Login,
    Logout,
    AuthedRoute
} from "./components/Auth";
import { Landing } from "./components/Landing";
import { BoardsView } from "./components/boards/BoardsView";
import { BoardView } from "./components/board/BoardView";
import { Header } from "./components/header/Header";

// import { BoardView } from "./components/BoardView";

const StyledPage = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export default () => {
    return (
        <ThemeProvider theme={THEME}>
            <StyledPage>
                <CreateGlobalStyle />
                <Router>
                    <MainContentContainer>
                        <Route exact path="/" component={() => <Redirect to="/landing" />} />
                        <Route path="/landing" component={Landing} />

                        <Route
                            exact
                            path="/welcome"
                            component={() => <Redirect to="/welcome/login" />}
                        />
                        <Route path="/welcome/login" component={Login}/>
                        <AuthedRoute path="/logout" component={Logout} />
                        
                        <AuthedRoute path="/app" component={AuthenticatedApp} />
                    </MainContentContainer>
                </Router>
            </StyledPage>
        </ThemeProvider>
    );
}

const AuthenticatedApp = () => {
    return (
        <Switch>
            <Route exact path="/app/boards" component={BoardsView}/>
            <Route path="/app/boards/:id" component={BoardView}/>
        </Switch>
    )
}

const MainContentContainer = styled.div`
    height: 0;
    flex-grow: 1;
    width: 100%;
`;
