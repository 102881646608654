export const THEME = {
  brand: {
    primaryColor: "#32308A",
    primaryFont: {
      family: "Sen",
      regularWeight: 400,
      boldWeight: 700,
      extraBoldWeight: 800
    }
  },

  bg: {
    color: "#F8F8F8"
  },

  fg: {
    color: "#FFF",
    text: {
      color: "#383838",
      secondaryColor: "#666670"
    },

    shadow: {
      partial: "1px 0px rgba(0, 0, 0, 0.25)",
      distance: 1.5,
      y: (height: number) => `0px ${THEME.fg.shadow.distance * height}px ${THEME.fg.shadow.partial}`,
      x: (height: number) => `${THEME.fg.shadow.distance * height}px 0px ${THEME.fg.shadow.partial}`
    }
  }
};
