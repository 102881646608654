import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

export const StyledChecklistItem = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledChecklistBox = styled.input`

`;

export const StyledChecklistText = styled(TextareaAutosize)`
  flex-grow: 1;
  font-family: ${props => props.theme.brand?.primaryFont.family};
  font-weight: ${props => props.theme.brand?.primaryFont.regularWeight};
  font-size: 16px;
  border: none;
  padding: 0px;
  resize: none;

  input:checked + & {
    text-decoration: line-through;
    color: #999;
  }
`;