import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    html, body, #root {
        height: 100%;
    }

    body {
        font-family: 'Open Sans', sans-serif;
        color: #454545;
        background: ${props => props.theme.bg.color};
        margin: 0;

        & h1 {
            font-family: 'Catamaran', sans-serif;
            font-weight: 700;
            font-size: 1.7rem;
        }

        & a {
            color: #454545;
            font-weight: 500;
            text-decoration: none;
            &:hover {
                // border-bottom: 1px solid #32308A;
                // border-bottom: 1px solid #454545;
                color: #32308A;
            }
        }
    }
`;