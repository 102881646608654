import boardwalk from "../";
import { BoardData } from "../boards";
import { UserData, UserName } from "../users";
import { BaseQuery } from "../base";
import { BoardQuery, PublicBoardQuery } from "./boards";
import { QueryAggregator } from "../queries";

/**
 * Get boards that the user has at least read permission for
 */
export class UserReadBoardQuery extends BaseQuery<BoardData> {
    /**
     * @param id User id
     */
    constructor(id: string) {
        // Checking if permission >= 0 equivalent to having at least 'read' permission
        super(boardwalk.boards.ref.where(`users.${id}.permission`, 'in', ['owner', 'write', 'read']));
    }
}

export default class UserBoardQueryAggregator extends QueryAggregator<BoardData, BoardQuery> {
    // These are also exposed in the aggregator so they can be queried individually
    userReadBoards: UserReadBoardQuery;
    publicBoards: PublicBoardQuery;
    /**
     * @param id User id
     */
    constructor(id: string) {
        const userReadBoards = new UserReadBoardQuery(id);
        const publicBoards = new PublicBoardQuery();

        super([userReadBoards, publicBoards]);
        this.userReadBoards = userReadBoards;
        this.publicBoards = publicBoards;
    }
}

export class UserQuery extends BaseQuery<UserData> {
    constructor(name: UserName) {
        super(boardwalk.users.ref.where('name.first', '==', name.first).where('name.last', '==', name.last));
    }
}
