import { BaseCollection, BaseDocument, BaseData } from "../../base";

import { Board } from "..";

export interface MemberData extends BaseData {
  name: {
    first: string;
    last: string;
  };
  email: string;
}

export class Members extends BaseCollection<MemberData> {
  constructor(board: Board) {
    super(board.ref.collection("members"));
  }
  member(id: string) {
    return new Member(id, this);
  }
}

export class Member extends BaseDocument<MemberData> {
  constructor(id: string, members: Members) {
    super(members.ref.doc(id));
  }
}
