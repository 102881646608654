import styled, {css} from 'styled-components';


export const StyledMetaSectionView = styled.div``;

export const StyledMetaSectionTitle = styled.div`
  text-transform: uppercase;
  font-family: ${(props) => props.theme.brand.primaryFont.family};
  font-weight: ${(props) => props.theme.brand.primaryFont.regularWeight};
  color: white;
  font-size: 14px;
  margin-top: 40px;
  margin-bottom: 12px;
`;

export const StyledMetaSectionContent = styled.div``;

export const StyledMetaSectionItem = styled.div`
  font-family: ${(props) => props.theme.brand.primaryFont.family};
  font-weight: ${(props) => props.theme.brand.primaryFont.regularWeight};
  color: white;
  font-size: 14px;
  padding: 8px 20px;
  margin-left: -20px;
  margin-right: -20px;

  ${props => props.selected && css`
    background: ${props => props.selectedColor || "#2497F0"};
  `}

  cursor: pointer;

  position: relative;
  & .hoverpane {
    width: 100%;
    height: 100%;
    background: transparent;
    position: absolute;
    left: 0;
    top: 0;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
    &:active {
      background: rgba(0, 0, 0, 0.1);
    }
  }
`;
