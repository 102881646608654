import styled, {css} from "styled-components";
import {isMobile} from "react-device-detect";

export const StyledMetaView = styled.div`
  width: 230px;
  height: 100%;

  display: flex;
  flex-direction: column;

  background: ${props => props.background || "#32308A"};
  padding: 40px 20px;
  box-sizing: border-box;
  // border-right: 1px solid rgb(203, 212, 219);

  .content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  ${isMobile && css`
    height: 48px;
    align-items: center;
    width: 100%;
    flex-direction: row;

    .content {
      display: none;
      ${props => props.showContent && css`
        background: ${props => props.background || "#32308A"};
        position: fixed;
        height: calc(100% - 80px);
        width: 230px;
        top: 0;
        left: 0;
        padding: 40px 20px;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
        z-index: 100;
        display: flex;
      `}
    }
  `}
`; 