import styled, {css} from "styled-components";
import {isMobile} from "react-device-detect";

export const StyledDetailedComponentView = styled.div`
  border-radius: 3px;
  background: white;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(203, 212, 219);
  // border: 1px solid #eeeeee;
  &:hover {
    // background: #F8F8F8;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
  }
`;

export const StyledRegularComponentView = styled(StyledDetailedComponentView)`
  width: 300px;
  margin: 12px;
  ${isMobile && css`
    width: calc(100% - 24px);
  `}
`;
export const StyledExpandedComponentView = styled(StyledDetailedComponentView)`
  width: 500px;
  flex-grow: 1;
  height: 100%;
`;

export const StyledDetailedComponentViewContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 12px;
  overflow: auto;
`;
