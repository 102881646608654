import React, { useEffect, useState } from 'react';
import { Board, BoardData } from '../../../../../../api/src/boards';
import { useMountedEffect } from '../../../../hooks/utils';

enum PermissionLevel {
  private = '0',
  publicRead = '1',
  publicUpdate = '2'
}

/**
 * Map the permissions stored in Firestore
 * to a selectable value to be displayed for the user
 * @param data BoardData
 */
const permissionLevelMap = (data: BoardData) => {
  if (!data || !data.public) return null;

  const read = data.public.read;
  const update = data.public.update;

  // Anyone can view and edit
  if (read && update) return '2';
  // Anyone can view
  else if (read && !update) return '1';
  // Private
  else return '0';
}

/**
 * Map a selectable value used for display to
 * its representation in the board in Firestore
 */
const levelPermissionMap = (level: PermissionLevel) => {
  if (level == PermissionLevel.private) return { read: false, update: false }
  else if (level == PermissionLevel.publicRead) return { read: true, update: false}
  else return { read: true, update: true }
}

interface PermissionSelectorProps {
  board: Board;
}

export const PermissionSelector = (props: PermissionSelectorProps) => {
  // Board permission public states
  const [permLevel, setPermLevel] = useState(() => permissionLevelMap(props.board && props.board.lastData()));
  const [updating, setUpdating] = useState(false);

  useMountedEffect(() => {
    setPermLevel(permissionLevelMap(props.board && props.board.lastData()));
  }, props.board)

  const onPermissionChange = (event) => {
    setUpdating(true);
    const newPermLevel = event.target.value
    const newPerms = levelPermissionMap(newPermLevel);
    props.board.update({ public: newPerms }).then(() => {
      setUpdating(false);
      setPermLevel(newPermLevel);
    });
  }

  return (
    <>
      <div style={{fontFamily: "Sen", marginBottom: 8}}>
        Board Permissions
      </div>
      <select 
        name="permission"
        value={permLevel}
        onChange={onPermissionChange}
        style={{fontFamily: "Sen", marginBottom: 8}}
      >
        <option hidden></option>
        <option value="0">Private</option>
        <option value="1">Anyone can view</option>
        <option value="2">Anyone can view and edit</option>
      </select>
      { updating &&
        <span style={{marginLeft: 8, fontSize: '0.6em', color: 'red'}}>
          Updating permissions...
        </span> 
      }
    </>
  )
}
