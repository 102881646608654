import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { UserQuery } from "../../../../../../api/src/queries/users";
import { Board, BoardData } from '../../../../../../api/src/boards';
import { useCurrentUser } from '../../../../hooks/users';

interface MemberControlProps {
  board: Board;
}

export const MemberPanel = (props: MemberControlProps) => {
  return (
    <>
      <div style={{fontFamily: "Sen", marginBottom: 8}}>
        Manage Members
      </div>
      <AddMemberControl board={props.board} />
      {/* <ShareableLinkControl board={props.board} /> */}
    </>
  );
}

const MemberInput = styled.input`
  width: 80%;
  border: none;
  padding: 10px;
  background: rgba(0, 0, 0, 0.06);
  font-family: ${props => props.theme.brand.primaryFont.family};
  margin-bottom: 8px;
  margin-right: 4px;
  box-sizing: border-box;
`;

export const AddMemberControl = (props: MemberControlProps) => {
  const [showAdder, setShowAdder] = useState(false);
  const [user, userData, id] = useCurrentUser();
  const [first, setFirst] = useState('');
  const [last, setLast] = useState('');
  const [permission, setPermission] = useState('read');
  const [error, setError] = useState(false);

  const handleAddMember = () => {
    const query = new UserQuery({ first: first, last: last});
    query.get().then(data => {
      let newMember;
      if (data && Array.isArray(data) && data.length > 0) {
        newMember = data[0];
      } else if (data && !Array.isArray(data)) {
        newMember = data;
      } else {
        setError(true);
        return;
      }

      // Can't update permissions on yourself!
      if (newMember.id === user.ref.id) {
        setError(true);
        return;
      }

      props.board.update({
        [`users.${newMember.id}`]: {
          permission: permission,
          name: {
            first: newMember.name.first,
            last: newMember.name.last
          }
        }
      }).then(() => {
        setError(false);
      }).catch(e => {
        setError(true);
      });
    });

    setFirst('');
    setLast('');
  }

  return (
    <>
      {
        error ? 
          <div style={{fontFamily: "Sen", marginBottom: 8, color: "red"}}>
            Unable add member. Permission denied or incorrect name.
          </div> : 
          null
      }
      <select 
        name="permission"
        value={permission}
        onChange={e => setPermission(e.target.value)}
        style={{fontFamily: "Sen", marginBottom: 8}}
      >
        <option value="read">New member can view</option>
        <option value="write">New member can view and edit</option>
      </select>
      <div style={{fontFamily: "Sen", marginBottom: 8, display: 'flex'}}>
      {
        showAdder ? 
          <>
            <MemberInput placeholder="First" value={first} onChange={e => setFirst(e.target.value)} autoFocus />
            <MemberInput placeholder="Last" value={last} onChange={e => setLast(e.target.value)} />
            <button
              style={{fontFamily: "Sen", float: 'right', marginBottom: 8, marginRight: 4, width: '20%'}}
              onClick={handleAddMember}
            >
              Add
            </button>
            <button
              style={{fontFamily: "Sen", float: 'right', marginBottom: 8, width: '20%'}}
              onClick={() => setShowAdder(false)}
            >
              Hide
            </button>
          </>
          :
          <>
            <button style={{fontFamily: "Sen"}} onClick={() => setShowAdder(true)}>Add Member</button>
          </>
      }
      </div>
    </>
  )
}

export const ShareableLinkControl = (props: MemberControlProps) => {
  const [showLink, setShowLink] = useState(false);

  return (
    <div style={{fontFamily: "Sen", marginBottom: 8}}>
      {
      !showLink ? 
        <button style={{fontFamily: "Sen"}} onClick={() => setShowLink(true)}>Get Shareable Link</button> :
        <div>
          Hello sharelink
          <button style={{fontFamily: "Sen"}} onClick={() => setShowLink(false)}>Hide</button>
        </div>
      }
    </div>
  );
}
