import {
  BaseComponent,
  BaseComponentData,
} from "boardwalk-fb/src/boards/components/base";
import React, { useEffect, useState } from "react";
import styled , {createGlobalStyle} from "styled-components";
import {
  StyledDetailedComponentView,
  StyledDetailedComponentViewContent,
  StyledRegularComponentView,
  StyledExpandedComponentView
} from './DetailedComponentView.style';
import { HeaderView } from "./header/HeaderView";
import { BaseComponentViewProps } from "../base";
import ReactTags from "react-tag-autocomplete";

export const Tagger = (props) => {
  const {className, ...tagProps} = props;

  return (
    <div className={className}>
      <ReactTags
        {...tagProps}
        placeholderText={tagProps.tags.length == 0 ? undefined : " + "}
      />
    </div>
  );
}

export const StyledTagger = styled(Tagger)`
  .react-tags {
    display: flex;
    flex-direction: row-reverse;
    padding: 0px 12px;

    .react-tags__selected {
      white-space: nowrap;
      flex-grow: 1;
      flex-basis: 0;
      overflow: auto;
      padding-right: 0;
    }

    .react-tags__search {
      float: left;
      padding: 0px 8px;
      padding-left: 0;
      margin-bottom: 8px;
      flex-shrink: 0;

      input {
        padding: 3px;
        border: none;
        border-radius: 3px;
        background: rgba(0, 0, 0, 0.06);
        font-family: ${props => props.theme.brand.primaryFont.family};
      }
    }
  }
  button {
    font-family: Sen;
    font-size: 12px;
    border-radius: 3px;
    background: none;
    padding: 4px 8px;
    border: 1px solid #ddd;
    color: #454545;
    margin: 0px 2px;
  }
`;

export const RegularComponentView = <C extends BaseComponent<any>>(props: BaseComponentViewProps<C>) => {
  const { tags } = props.component.lastData();
  const tagDict = {};
  for (let tag of tags) {
    tagDict[tag] = tag;
  }
  const originalTagString = tags.join(", ");
  const [tagString, setTagString] = useState<string>(originalTagString);

  return (
    <StyledRegularComponentView>
      <HeaderView
        component={props.component}
        onTitleChange={(name) => props.component.update({ name })}
        onDescriptionChange={(description) => props.component.update({ description })}
        data-testid="header"
      />
      <StyledDetailedComponentViewContent data-testid="content">
        {props.content}
      </StyledDetailedComponentViewContent>
      <StyledTagger
        tags={Object.keys(tagDict).map(tag => ({id: tag, name: tag}))}
        onAddition={tag => {
          tagDict[tag.name] = tag.name;
          props.component.update({tags: Object.keys(tagDict)});
        }}
        onDelete={i => {
          const newTags = [...tags];
          newTags.splice(i, 1);
          props.component.update({tags: newTags});
        }}
        allowNew
        allowBackspace
      />
    </StyledRegularComponentView>
  );
};

export const ExpandedComponentView = <C extends BaseComponent<any>>(props: BaseComponentViewProps<C>) => {
  return (
    <StyledExpandedComponentView>
      <HeaderView
        component={props.component}
        onTitleChange={(name) => props.component.update({ name })}
        onDescriptionChange={(description) => props.component.update({ description })}
        data-testid="header"
      />
      <StyledDetailedComponentViewContent data-testid="content">
        {props.content}
      </StyledDetailedComponentViewContent>
    </StyledExpandedComponentView>
  );
};
