import React from 'react';

import {
  TextComponent,
  TextComponentData,
} from "boardwalk-fb/src/boards/components/text";
import styled from "styled-components";
import { BaseComponentContentProps, BaseComponentWrapper } from '../base-component/BaseComponentWrapper';
import { StyledTextarea } from './TextComponentView.style';
import Editor from "rich-markdown-editor";

export class TextComponentView extends BaseComponentWrapper<TextComponent> {
  getRegularView() {
    return super.getRegularView(
      <TextComponentContentView
        component={this.component}
      />
    );
  }

  getExpandedView() {
    return super.getExpandedView(
      <TextComponentContentView
        component={this.component}
      />
    );
  }
}

const StyledEditor = styled(Editor)`
  border-radius: 3px;
  overflow: hidden;
  background: none;

  .ProseMirror {
    padding: 0px;
  }
  & .placeholder::before {
    color: #777 !important;
  }
  & p {
    font-size: 14px;
  }
  & * {
    font-family: Sen;
  }
  h1::before, h2::before, h3::before, h4::before, h5::before {
    display: none !important;
  }
  h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child {
    margin-top: 0;
  }
`;

export const TextComponentContentView = (props: BaseComponentContentProps<TextComponent>) => {
  const data = props.component.lastData();
  const [text, setText] = React.useState(data.data.text);
  return (
    <div>
      {data.data.text !== text && <div style={{
        fontFamily: "Sen",
        fontSize: 10,
        background: "#ccc",
        padding: "4px 6px",
        width: "fit-content",
        margin: "8px 0px",
        borderRadius: "10px"
      }}>unsaved changes</div>}
      <StyledEditor
        headingsOffset={6}
        defaultValue={data.data.text}
        onSave={() => props.component.updateText(text)}
        onChange={getText => setText(getText())}
      />
    </div>
  )
};