// Below used for type-safe access and assignment with objects

/**
 * Get value from object in a way that is TS-friendly, removing implicit 'any' type issues.
 * @param obj Object being accessed.
 * @param key Object key that a value is needed for.
 */
export const getKVPair = <T extends Object, U extends keyof T>(obj:  T, key: U) => obj[key];
/**
 * 
 * @param obj Object being accessed.
 * @param key Object key that a value is needed for.
 * @param value Value at `key` in `obj`
 */
export const setKVPair = <T extends Object, U extends keyof T>(obj:  T, key: U, value: T[U]) => obj[key] = value;