import { useState, useEffect, useRef } from "react";
import boardwalk from "boardwalk-fb/src";
import { User, UserData } from 'boardwalk-fb/src/users';

let cachedUser: User = undefined;
let cachedUserId: string = JSON.parse(localStorage.getItem('currentUserId')) || undefined;
let cachedUserData: UserData = undefined;

export const useCurrentUser = (): [User, UserData, string] => {
  const [user, setUser] = useState<User>(cachedUser);
  const [userData, setUserData] = useState(cachedUserData);

  useEffect(() => {
    const cancelAuthStateChangeListener = boardwalk.auth.onAuthStateChanged((authUser) => {
      cachedUser = authUser ? boardwalk.users.user(authUser.uid) : undefined;
      if (cachedUser) {
        localStorage.setItem('currentUserId', JSON.stringify(cachedUser.ref.id));
        cachedUserId = cachedUser.ref.id;
      } else {
        localStorage.removeItem('currentUserId');
        cachedUserId = null;
      }
      setUser(cachedUser);
    });
    return cancelAuthStateChangeListener;
  }, []);

  useEffect(() => {
    let cancelListener;
    if (user) {
      cancelListener = user.listen((userData: UserData) => {
        cachedUserData = userData;
        setUserData(userData);
      });
    }
    return () => {
      cancelListener && cancelListener();
    };
  }, [user?.ref?.id]);
  return [user, userData, cachedUserId];
};