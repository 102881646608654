import styled, {css} from "styled-components";
import {isMobile} from "react-device-detect";

export const StyledBoardView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  ${isMobile && css`
    flex-direction: column;
  `}
`;

export const StyledComponentGallery = styled.div`
  width: 100%;
  height: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;