import {GettablePlural} from "./gettable";
import {BaseData} from "./data";

const COLLECTION_CACHE: {[key: string]: BaseData[]} = {};

/**
 * An abstraction over the firebase collection.
 * This object supports reads, listens, and additions. 
 */
export class BaseCollection<T extends BaseData> extends GettablePlural<T> {
  ref: firebase.firestore.CollectionReference;
  constructor(ref: firebase.firestore.CollectionReference) {
    super();
    this.ref = ref;
  }

  snapshotToData(snapshot: firebase.firestore.QuerySnapshot): T[] {
    const items = super.snapshotToData(snapshot);
    this.updateCache(items);
    return items;
  }

  async add(data: Omit<T, "id" | "ref">) {
    return this.ref.add(data);
  }

  async put(id: string, data: Omit<T, "id" | "ref">) {
    return this.ref.doc(id).set(data);
  }

  private updateCache(data: T[]) {
    if (this.ref && this.ref.id) {
      COLLECTION_CACHE[this.ref.id] = data;
    }
  }

  public lastData(): T[] {
    return COLLECTION_CACHE[this.ref.id] as T[];
  }
}