import { Board } from 'boardwalk-fb/src/boards';
import { BaseComponent, BaseComponentData } from 'boardwalk-fb/src/boards/components/base';
import { LoadingView } from "./loading/LoadingView";
import { RegularComponentView, ExpandedComponentView } from './views/detailed/DetailedComponentView';
import { EmbeddedComponentView } from './views/embedded/EmbeddedComponentView';
import React from 'react';
import useContextMenu from "react-use-context-menu";
import {isMobile} from "react-device-detect";
import styled, {css} from "styled-components";

let DRAGGED_COMPONENT = undefined;
export const setDraggedComponent = (c) => DRAGGED_COMPONENT = c;
export const getDraggedComponent = () => DRAGGED_COMPONENT;

export enum ComponentContext {
  REGULAR,
  EXPANDED,
  EMBEDDED
}

export type ComponentContent<C extends BaseComponent<any>> =  React.ReactElement<BaseComponentContentProps<C>>;
export interface BaseComponentContentProps<C extends BaseComponent<any>> {
  component: C;
  content?: ComponentContent<C>;
};

export interface BaseComponentProps {
  id: string;
  board: Board;
  context: ComponentContext;
  onToggleExpand?: () => void;
}

export interface BaseComponentState {
  lastUpdateTime: Date,
  showDebug: boolean
}

const ContextMenu = styled.nav`
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0px 8px 32px rgba(20, 20, 20, 0.2);
  width: 150px;
  border-radius: 4px;

  ${isMobile && css`
    left: 50% !important;
    top: 50% !important;
    width: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
  `}
`;

const ContextMenuBlur = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
`

const ContextMenuItemStyle = styled.div`
  padding: 8px;
  font-family: Sen;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
  user-select: none;
  ${props => props.dangerous && css`
    color: #f73636;
  `}
  &:hover {
    background #eee;
  }
  ${isMobile && css`
    font-size: 20px;
    text-align: center;
  `}
`;

const ContextMenuItem = ({label, handler, dangerous, ...props}) => {
  const [showConfirm, setShowConfirm] = React.useState(false);
  return (
    <ContextMenuItemStyle
      dangerous={dangerous}
      onClick={() => {
        if (dangerous) {
          if (showConfirm) {
            handler();
            setShowConfirm(false);
          } else {
            setShowConfirm(true);
          }
        } else {
          handler();
        }
      }}
      onBlur={() => setShowConfirm(false)}
      {...props}
    >{showConfirm ? "Are you sure?" : label}</ContextMenuItemStyle>
  )
}

export abstract class BaseComponentWrapper <C extends BaseComponent<any>, S extends BaseComponentState = BaseComponentState> extends React.Component<BaseComponentProps, S> {
  component: C;
  
  constructor(props) {
    super(props);
    this.state = {
      lastUpdateTime: undefined,
      showDebug: false
    } as S;
  }
  
  componentDidMount() {
    const {board, id} = this.props;
    board.components.component(id).then((component: C) => {
      this.component = component;
      this.component.listen(() => {
        this.setState({lastUpdateTime: new Date()});
        this.componentDidMountDerived();
      });
    })
  }
  
  /** Used to let component wrappers handle any loading that depends on this.component being available. */
  componentDidMountDerived() {}

  render() {
    const { component } = this;
    const loaded = component && component.lastData() && true;

    const { context, onToggleExpand } = this.props;

    return (
      <>
        {!loaded && <LoadingView data-testid="view-loading"/>}
        {loaded && (
          <BaseComponentView
            wrapper={this}
            context={context}
            onToggleExpand={onToggleExpand}
            data-testid="view-loaded"
          />
        )}
      </>
    )
  }

  /** Get component-specific UI based on given component data.
   *  TODO: expand this with more methods for specific sizes, contexts (e.g embedded), etc.
   */
  getRegularView(content?: ComponentContent<C>): React.ReactElement {
    return (
      <RegularComponentView<C>
        component={this.component}
        content={content}
      />
    );
  };

  getExpandedView(content?: ComponentContent<C>): React.ReactElement {
    return (
      <ExpandedComponentView<C>
        component={this.component}
        content={content}
      />
    );
  };

  getEmbeddedView(content?: React.ReactElement<BaseComponentContentProps<C>>): React.ReactElement {
    return (
      <EmbeddedComponentView<C>
        component={this.component}
        content={content}
      />
    );
  };

  getMenuOptions() {
    return [
      {label: "Delete", dangerous: true, handler: () => this.component.delete()},
      {label: this.props.context === ComponentContext.EXPANDED ? "Collapse" : "Expand", handler: this.props.onToggleExpand}
    ];
  }

  /** Get a react component (specific to Text, Link, etc), inferring type from component data. */
  static fromComponent(componentData: BaseComponentData, board: Board, context: ComponentContext, onToggleExpand?: () => void) {
    const component = types[componentData.componentType];
    if (!component) {
      return null;
    }
    return React.createElement(component, {
      board,
      id: componentData.id,
      context,
      key: componentData.id,
      onToggleExpand
    });
  }
}

export const DebugData = ({ component }) => {
  const data = component.lastData();
  const {ref, ...filteredData} = data;
  return (
    <pre>
      <code>
        {JSON.stringify({refId: ref.id}, undefined, 4)}
        {JSON.stringify(filteredData, undefined, 4)}
      </code>
    </pre>
  );
};

const RootComponentView = styled.div`
  display: flex;
  flex-direction: column;
  width: ${isMobile ? "100%" : "none"};
  user-drag: element;
`;
interface BaseComponentView<C extends BaseComponentWrapper<any>> {
  wrapper: C;
  context: ComponentContext;
  onToggleExpand: () => void;
}

export const BaseComponentView = <C extends BaseComponentWrapper<any>>(props: BaseComponentView<C>) => {
  const {context, wrapper, onToggleExpand} = props;
  const [
    bindContextMenu,
    bindContextMenuItems,
    useContextTrigger,
    {isVisible}
  ] = useContextMenu();
  const [bindContextTrigger] = useContextTrigger({holdToDisplay: isMobile ? 600: 10000});

  return (<>
    <RootComponentView
      onDoubleClick={() => process.env.NODE_ENV === "development" &&  wrapper.setState({showDebug: !wrapper.state.showDebug})}
      data-testid="view-loaded"
      {...bindContextTrigger}
      onDragStart={e => {
        e.stopPropagation();
        e.kind = "component";
        setDraggedComponent(wrapper.component);
      }}
      onDragEnd={e => {
        setDraggedComponent(undefined);
        e.kind = undefined;
      }}
    >
      {isVisible && isMobile && <ContextMenuBlur/>}
      <ContextMenu {...bindContextMenu}>
        {
          wrapper.getMenuOptions().filter(option => option !== undefined).map(({label, handler, dangerous}) => {
            return (
              <ContextMenuItem {...bindContextMenuItems} handler={handler} dangerous={dangerous} label={label}/>
            )
          })
        }
      </ContextMenu>
      {context == ComponentContext.REGULAR && wrapper.getRegularView()}
      {context == ComponentContext.EXPANDED && wrapper.getExpandedView()}
      {context == ComponentContext.EMBEDDED && wrapper.getEmbeddedView()}
      {/* Temporary, only to help with debug. */}
      {wrapper.state.showDebug && <DebugData component={wrapper.component} data-testid="debug" />}  
    </RootComponentView>
    </>
  )
}

/** These need to be at the bottom to avoid circular import errors. */

import { DocumentComponentView } from '../storage-components/document-component/DocumentComponentView';
import { ImageComponentView } from '../storage-components/image-component/ImageComponentView';
import { LinkComponentView } from '../link-component/LinkComponentView';
import { TextComponentView } from '../text-component/TextComponentView';
import { ChecklistComponentView, ChecklistItemComponentView } from '../checklist-component/ChecklistComponentView';
import { DrawingComponentView } from '../drawing-component/DrawingComponentView';
import useEffect from 'react';

const types = {
  text: TextComponentView,
  link: LinkComponentView,
  document: DocumentComponentView,
  image: ImageComponentView,
  checklist: ChecklistComponentView,
  "checklist-item": ChecklistItemComponentView,
  drawing: DrawingComponentView
};