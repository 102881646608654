export const creationReducer = (state, action) => {
    switch (action.type) {
        case 'field': {
            return {
                ...state,
                [action.fieldName]: action.payload
            };
        }
        case 'error': {
            return {
                ...state,
                error: 'Invalid field'
            };
        }
        default:
            return state;
    }
}