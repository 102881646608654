import db from "../firebase";
import { BaseDocument, BaseCollection, BaseData } from "../base";

import { Members } from "./members";
import { Components } from "./components/base";
import { BaseComponentData } from "./components/base";

export interface BoardData extends BaseData {
  name: string;
  description: string;
  tags: string[];
  users: {
    [id: string]: Member;
  };
  public: {
    read: boolean;
    update: boolean;
  }
  primaryColor?: string;
  secondaryColor?: string;
}

export interface Member {
  permission: Permission;
  name?: {
    first?: string;
    last?: string;
  }
}

// Can switch to using a number 0, 1, 2 later
type Permission = 'read' | 'write' | 'owner';
// enum Permission {
//   read = 'read',
//   write = 'write',
//   owner = 'owner'
// }

export class Boards extends BaseCollection<BoardData> {
  constructor() {
    super(db.collection("boards"));
  }

  board(id: string): Board {
    return new Board(id, this);
  }
}

export class Board extends BaseDocument<BoardData> {
  members: Members;
  components: Components<BaseComponentData>;

  constructor(id: string, parent: Boards) {
    super(parent.ref.doc(id));

    this.members = new Members(this);
    this.components = new Components(this);
  }
}

export default new Boards();
