import { BaseQuery } from "../base";
import { BoardData } from "../boards";
import boardwalk from "../";

export class BoardQuery extends BaseQuery<BoardData>{
    constructor(ref: firebase.firestore.Query) {
        super(ref);
    }
}

export class PublicBoardQuery extends BoardQuery {
    constructor() {
      super(boardwalk.boards.ref.where('public.read', '==', true));
    }
}