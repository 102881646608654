import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { firebaseConfig, localFirestoreHost } from "./config";

if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();

if (process.env.NODE_ENV === 'development') {
    console.log('Dev environment detected, using Firestore emulator')
    // Point Firestore to emulator in local env
    db.settings({
        host: localFirestoreHost,
        ssl: false
    });
} else {
    console.log('Using production Firestore')
}

export default db;

export const auth = firebase.auth();
export const storage = firebase.storage();
