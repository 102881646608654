import boardwalk from "boardwalk-fb/src";
import { Boardwalk } from "boardwalk-fb/src";
import firebase from "firebase/app";
import "firebase/auth";

export const googleSignIn = () => {
    let provider = new firebase.auth.GoogleAuthProvider();
    //@ts-ignore
    return (boardwalk as Boardwalk).auth.signInWithPopup(provider).then(result => {
        const user = boardwalk.users.user(result.user.uid);
        user.ref.set({
            name: {
                //@ts-ignore
                first: result.additionalUserInfo.profile.given_name,
                //@ts-ignore
                last: result.additionalUserInfo.profile.family_name
            }
        }).catch(err => console.log('Unable to set new user info, error:', err.message));
        return user;
    }).catch(err => console.log('Unable to sign in with Google Auth, error:', err.message));
}

export const logOut = () => {
    return boardwalk.auth.signOut().then(() => {
        // Anything to do here?
        localStorage.removeItem('currentUserId');
        console.log("User signed out.")
    }).catch(err => {
        console.log(err.message);
    });
}