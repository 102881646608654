import styled, {css} from "styled-components";

export const StyledImageView = styled.img`
  border-radius: 1px;
  // box-shadow: ${props => props.theme.fg.shadow.y(1)};
  object-fit: contain;

  ${props => props.expanded ? css`
    height: 0;
    flex-grow: 1;
  `: css`
    width: 100%;
  `}
`;