import boardwalk from "../../";
import { BaseCollection, BaseData, BaseDocument, BaseQuery } from "../../base";
import { UserData } from "../../users";
import { BaseComponentData, BaseComponent, Components } from "./base";

export interface ChecklistComponentData extends BaseComponentData {
  data: {}
}

export interface ChecklistItemComponentData extends BaseComponentData {
  data: {
    completed: boolean;
  }
}

export class ChecklistItemComponent extends BaseComponent<ChecklistItemComponentData> {
  static getDefaultData(userData: UserData, parent: string): Omit<ChecklistItemComponentData, "id" | "ref"> {
    return {
      ...super.getDefaultData(userData, parent),
      componentType: "checklist-item",
      data: {
        completed: false
      }
    }
  }
}

export class ChecklistItems extends BaseQuery<ChecklistItemComponentData> {
  constructor(checklistId: string, components: Components<any>) {
    super(components.ref.where("parent", "==", checklistId));
  }
}

export class ChecklistComponent extends BaseComponent<ChecklistComponentData> {
  items: ChecklistItems;
  constructor(id: string, components: Components<ChecklistComponentData>) {
    super(id, components);
    this.items = new ChecklistItems(id, components);
  }

  static getDefaultData(userData: UserData, parent: string, data: any = {}): Omit<ChecklistComponentData, "id" | "ref"> {
    return {
      ...super.getDefaultData(userData, parent),
      componentType: "checklist",
      data: {
        ...data
      }
    }
  }

  async addItem(userData: UserData) {
    return this.board.components.add(ChecklistItemComponent.getDefaultData(userData, this.ref.id));
  }
}