import { BaseComponent, BaseComponentData, Components } from "./base";
import { TextComponentData, TextComponent } from "./text";
import { LinkComponentData, LinkComponent } from "./link";
import { ChecklistComponentData, ChecklistComponent, ChecklistItemComponentData, ChecklistItemComponent } from "./checklist";
import { DocumentComponentData, DocumentComponent } from "./storage/document";
import { ImageComponentData, ImageComponent } from "./storage/image";
import { DrawingComponent, DrawingComponentData } from "./drawing";

export const componentTypeMap = {
  text: (id: string, components: Components<TextComponentData>) => {
    return new TextComponent(id, components);
  },
  link: (id: string, components: Components<LinkComponentData>) => {
    return new LinkComponent(id, components);
  },
  document: (id: string, components: Components<DocumentComponentData>) => {
    return new DocumentComponent(id, components);
  },
  image: (id: string, components: Components<ImageComponentData>) => {
    return new ImageComponent(id, components);
  },
  checklist: (id: string, components: Components<ChecklistComponentData>) => {
    return new ChecklistComponent(id, components);
  },
  "checklist-item": (id: string, components: Components<ChecklistItemComponentData>) => {
    return new ChecklistItemComponent(id, components);
  },
  drawing: (id: string, components: Components<DrawingComponentData>) => {
    return new DrawingComponent(id, components);
  }
};

// For use in /app where base-component/base-component-data combo is assumed
export type Component = BaseComponent<BaseComponentData>;
