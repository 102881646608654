import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

export const StyledHeaderView = styled.div`
  height: fit-content;
  padding: 8px 8px;
  border-bottom: 1px solid rgb(203, 212, 219);
`;

export const StyledHeaderTitle = styled(TextareaAutosize)`
  display: flex;
  align-items: center;
  border: none;
  width: 100%;
  resize: none;
  font-family: ${props => props.theme.brand.primaryFont.family};
  font-weight: ${props => props.theme.brand.primaryFont.boldWeight};
  font-size: 10pt;
  color: #6B6B6B;
  background: inherit;
`;

export const StyledHeaderDescription = styled(TextareaAutosize)`
  border: none;
  width: 100%;
  resize: none;
  font-family: ${props => props.theme.brand.primaryFont.family};
  font-width: ${props => props.theme.brand.primaryFont.regularWeight};
  background: inherit;
  ${StyledHeaderTitle}:not(:focus) ~ &:placeholder-shown:not(:focus):not(:active) {
    display: none;
  }
`;