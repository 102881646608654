import { Board, BoardData } from 'boardwalk-fb/src/boards';
import React, { ReactNode } from 'react';
import { BoardHeaderView } from '../board-header/BoardHeaderView';
import { StyledMainPanelContent, StyledMainPanelView } from './MainPanelView.style';
import { useBoard } from "../../../hooks/boards";
import { LinkComponent } from '../../../../../api/src/boards/components/link';
import { useCurrentUser } from '../../../hooks/users';
import { useLocation } from 'react-router-dom';
import qs from "qs";
import { TextComponent } from '../../../../../api/src/boards/components/text';
import { ImageComponent } from '../../../../../api/src/boards/components/storage/image';
import { DocumentComponent } from '../../../../../api/src/boards/components/storage/document';

export interface MainPanelViewProps {
  boardData: BoardData;
  children?: ReactNode;
}

export const MainPanelView = ({children, boardData}: MainPanelViewProps) => {
  const [hasDrag, setHasDrag] = React.useState(false);
  const [board] = useBoard(boardData.id);
  const [user, userData] = useCurrentUser();

  const location = useLocation();
  const search = location.search;
  const topics = qs.parse(search, {ignoreQueryPrefix: true}).topics;
  const tags = topics ? [...topics] : [];

  const onDrop = (event) => {
    event.preventDefault();
    setHasDrag(false);

    // Prevent duplicates.
    const addedItems = new Set();
    if (event.dataTransfer.items) {
      const items = [...event.dataTransfer.items];
      for (let item of items) {
        const kind = item.kind;
        const type = item.type;
        console.log(`DROP: ${kind} ${type}`);
        switch (kind) {
          case "string":
            item.getAsString(content => {
              if (content) {
                if (addedItems.has(content)) {
                  return;
                }
                addedItems.add(content);
                switch (type) {
                  case "text/uri-list":
                  case "text/plain":
                    if (content.includes("https://") || content.includes("http://")) {
                      // Treat as a link
                      board.components.add({
                        ...LinkComponent.getDefaultData(userData, boardData.id, {
                          link: content
                        }),
                        tags
                      });
                    } else {
                      // Treat as text
                      board.components.add({
                        ...TextComponent.getDefaultData(userData, boardData.id, {
                          text: content
                        }),
                        tags
                      });
                    }
                    break
                } 
              }
            });
            break;
          case "file":
            const file = item.getAsFile();
            if(type.includes("image/")) {
              // Treat as an image.
              (async () => {
                const component = await board.components.add({
                  ...ImageComponent.getDefaultData(userData, boardData.id),
                  tags
                }) as ImageComponent;
                const path = await component.fileStore.add(file, () => {});
                //@ts-ignore
                component.update({data: {path}});
              })();
            } else if (type === "application/pdf"){
              // Treat as a pdf
              (async () => {
                const component = await board.components.add({
                  ...DocumentComponent.getDefaultData(userData, boardData.id),
                  tags
                }) as DocumentComponent;
                const path = await component.fileStore.add(file, () => {});
                //@ts-ignore
                component.update({data: {path}});
              })();
            }
        }
      }
    }
  }
  return (
    <StyledMainPanelView onDrop={onDrop} onDragOver={(e) => { e.preventDefault(); if (e.kind !== "component") {setHasDrag(true)}}} onDragLeave={() => setHasDrag(false)} hasDrag={hasDrag}>
      {/* <BoardHeaderView boardData={boardData}/> */}
      <StyledMainPanelContent>
        {children}
      </StyledMainPanelContent>
    </StyledMainPanelView>
  )
}