import styled, {css} from 'styled-components';
import CanvasDraw from "react-canvas-draw";

export const StyledDrawingComponentView = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  ${props => props.expanded ? css`
    height: 0;
    flex-grow: 1;
  `: css`
    height: 250px;
  `}
`;

export const StyledDrawingArea = styled(CanvasDraw)`
  width: 100%;
  height: 0;
  flex-grow: 1;
`;