import React from "react";
import { Header } from "./header/Header";
import {Redirect} from "react-router-dom";

export const Landing = () => {
    return (
        <>
            <Redirect to="/app/boards"/>
        </>
    );
}