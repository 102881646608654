import { BaseCollection, BaseDocument, BaseData } from "../base";
import UserBoardQueryAggregator from "../queries/users";
import db from "../firebase";
import { RecentBoards } from "./recent-boards";

export interface UserData extends BaseData {
  name: UserName;
}

export interface UserName {
  first: string;
  last: string;
}

export class Users extends BaseCollection<UserData> {
  constructor() {
    super(db.collection("users"));
  }
  user(id: string) {
    return new User(id, this);
  }
}

export class User extends BaseDocument<UserData> {
  boards: UserBoardQueryAggregator;
  recentBoards: RecentBoards;
  constructor(id: string, users: Users) {
    super(users.ref.doc(id));
    this.boards = new UserBoardQueryAggregator(id);
    this.recentBoards = new RecentBoards(this);
  }
}
