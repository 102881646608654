import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

export const StyledLink = styled.a`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: ${props => props.theme.brand.primaryFont.family};
`;

export const StyledLinkEntry = styled(TextareaAutosize)`
  width: 100%;
  font-family: ${props => props.theme.brand.primaryFont.family};
  font-weight: ${props => props.theme.brand.primaryFont.regularWeight};
  font-size: 16px;
  border: none;
  padding: 0px;
  resize: none;
`;