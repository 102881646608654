import { BaseCollection, BaseDocument, BaseData } from "../../base";
import { User } from "..";
import { BoardData } from "../../boards";

export interface RecentBoardData extends BaseData {};

export class RecentBoards extends BaseCollection<RecentBoardData> {
  constructor(user: User) {
    super(user.ref.collection("recent-boards"));
  }
  recentBoard(id: string) {
    return new RecentBoard(id, this);
  }

  addFromBoardData(boardData: BoardData) {
    return this.put(boardData.id, {});
  }
}

export class RecentBoard extends BaseDocument<RecentBoardData> {
  constructor(id: string, recentBoards: RecentBoards) {
    super(recentBoards.ref.doc(id));
  }
}
