import React from "react";
import {
    Route,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import { googleSignIn, logOut } from "../auth";
import { useCurrentUser } from "../hooks/users";
import styled, {css} from "styled-components";
import {isMobile} from "react-device-detect";

// TODO: Pass error to show must log in to access this page
export const AuthedRoute = (props) => {
    const { component: Component, path, ...rest } = props;
    const [user, userData, userId] = useCurrentUser();

    return (
        <Route
            path={path} >
            {userId ? <Component { ...rest } /> : <Redirect to="/welcome/login" />}
        </Route>
    );
}

const StyledLoginView = styled.div`
    width: 200px;
    height: fit-content;
    transform: translate(-50%, -50%);
    background: white;
    padding: 24px;
    border-radius: 4px;
    position: fixed;
    left: 50%;
    top: 50%;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
`;


export const Login = () => {
    const [user, userData, userId] = useCurrentUser();
    let history = useHistory();
    let location = useLocation();
    const onGoogleSignInClick = (event) => {
        event.preventDefault();
        googleSignIn().then(() => {
            // This should allow us to redirect to wherever we came from but it doesn't?
            const { from } = location.state || { from: { pathname: "/" } };
            history.replace(from);
        });
    }

    return (
        userId ? (
            <Redirect to="/app/boards"/>
        ) : (<StyledLoginView>
                <div style={{fontFamily: "Sen", color: "#32308A", fontWeight: 700, textAlign: "center"}}>Boardwalk.</div>
                <br/>
                <button onClick={onGoogleSignInClick} style={{fontFamily: "Sen", border: "1px solid #ddd", borderRadius: 4, padding: 8, background: "none"}}>
                    Login with Google
                </button>
            </StyledLoginView>
        )
    );
}

export const Logout = () => {
    let history = useHistory();
    logOut().then(() => {
        history.push('/app/boards');
    });

    return <p>Logging out user...</p>
}