import React from 'react';
import { StyledSidebarView } from './SidebarView.style';

export interface SidebarViewProps {
  children?: React.ReactNode;
  background: string;
}

export const SidebarView = (props: SidebarViewProps) => {
  return (
    <StyledSidebarView background={props.background}>
      {props.children}
    </StyledSidebarView>
  )
}