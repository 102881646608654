import { useState, useEffect, useRef } from "react";
import {
  BaseComponent as Component,
  BaseComponentData as ComponentData,
} from "boardwalk-fb/src/boards/components/base";
import { Board } from "../../../api/src/boards";
import { BaseQuery } from "boardwalk-fb/src/base/query";

export const useComponents = (board: Board) => {
  const cachedComponents = useRef<ComponentData[]>([]);
  const [components, setComponents] = useState<ComponentData[]>(
    cachedComponents.current
  );

  useEffect(() => {
    if (board) {
      const cancelListener = new BaseQuery(board.components.ref.where("parent", "==", board.ref.id)).listen((components: ComponentData[]) => {
        cachedComponents.current = components;
        setComponents(cachedComponents.current);
      });
      return cancelListener;
    }
  }, [board?.ref?.id]);

  return components;
};

export const useComponent = (
  board: Board,
  id: string
): [Component<ComponentData>, ComponentData] => {
  const cachedComponent = useRef<Component<ComponentData>>(undefined);
  const cachedComponentData = useRef<ComponentData>(undefined);
  const [component, setComponent] = useState<Component<ComponentData>>(() => {
    return cachedComponent.current
  });
  const [componentData, setComponentData] = useState<ComponentData>(
    cachedComponentData.current
  );
  // TODO: use useAsyncEffect from utils so we don't have to track mounted here
  // useAsyncEffect needs a fix before this can happen
  const mounted = useRef(true);

  useEffect(() => {
    if (board) {
      board.components.component(id).then(component => {
        cachedComponent.current = component;
        if (mounted.current) setComponent(cachedComponent.current);
      });
    }
    return () => mounted.current = false;
  }, [board?.ref?.id]);

  useEffect(() => {
    if (component) {
      const cancelListener = component.listen((componentData) => {
        // TODO: Can we fix base so we don't need this cast?
        cachedComponentData.current = componentData as ComponentData;
        setComponentData(cachedComponentData.current);
      });
      return cancelListener;
    }
  }, [component?.ref?.id]);

  return [component, componentData];
};
