import { BaseComponentData, BaseComponent, Components } from "../base";
import { Storage } from "../../../storage";
export interface StorageComponentData extends BaseComponentData {
  data: {
    path: string;
  }
}

export abstract class StorageComponent<
  T extends BaseComponentData
> extends BaseComponent<T> {
  path: string;
  fileStore: Storage;
  constructor(id: string, components: Components<T>, path: string) {
    super(id, components);
    this.path = path;
    this.fileStore = new Storage(path);
  }

  abstract getAcceptableTypes(): string;
}