import { UserData } from "../../users";
import { BaseComponent, BaseComponentData, Components } from "./base";
import { TextComponentData } from "./text";


export interface LinkComponentData extends BaseComponentData {
  data: {
    link: string
  }
}

export class LinkComponent extends BaseComponent<LinkComponentData> {
  constructor(id: string, components: Components<LinkComponentData>) {
    super(id, components);
  }

  static getDefaultData(userData: UserData, parent: string, data: any = {}): Omit<LinkComponentData, "id" | "ref"> {
    return {
      ...super.getDefaultData(userData, parent),
      componentType: "link",
      data: {
        link: "",
        ...data
      }
    };
  }
}
