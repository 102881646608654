import { Board } from 'boardwalk-fb/src/boards';
import { BaseComponent, BaseComponentData } from 'boardwalk-fb/src/boards/components/base';
import React from 'react';
import { BaseComponentWrapper, ComponentContext } from '../../../BaseComponentWrapper';
import { StyledEmbeddedComponentContainerView } from './EmbeddedComponentContainerView.style';

interface EmbeddedComponentContainerViewProps {
  components: BaseComponentData[];
  board: Board;
}

export const EmbeddedComponentContainerView = (props: EmbeddedComponentContainerViewProps) => {
  return (
    <StyledEmbeddedComponentContainerView {...props}>
      {props.components.map(component => (
        BaseComponentWrapper.fromComponent(
          component,
          props.board,
          ComponentContext.EMBEDDED
        )
      ))}
    </StyledEmbeddedComponentContainerView>
  )
}