import axios from "axios";
import firebase from "firebase/app";
import "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { storage } from "../firebase";
export const root = storage.ref();

// metadata.name is a uuid used for a unique path, metadata.fileName is the 'real' name
interface ComponentStoreMeta extends firebase.storage.UploadMetadata {
    customMetadata: {
        fileName: string;
    };
}

export class Storage {
    ref: firebase.storage.Reference;
    constructor(path: string) {
        this.ref = root.child(path);
    }

    // To do: integrate progress monitoring, pause and cancel upload
    /**
     * Add a new file to Firebase storage, returns url to download the file
     * This url is stored in Firebase firestore document corresponding to the component
     * @param name Name that file should be stored with
     * @param file Actual file
     * @param metadata Optional, metadata for the file e.g. size, file type
     */
    async add(file: File, onProgress: (progress: number) => void) {
        try {
            const metadata: ComponentStoreMeta = {
                customMetadata: {
                    fileName: file.name
                }
            };
            const id = uuidv4();
            const cur = this.ref.child(id);
            const uploadTask = cur.put(file, metadata);
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, snapshot => {
                const progress = snapshot.bytesTransferred / snapshot.totalBytes;
                onProgress(progress);
            });
            await uploadTask;
            console.log(`File uploaded to storage, reference: ${cur.fullPath}`);
            // should return full path?
            return cur.fullPath;
        } catch (err) {
            console.log(err)
            return Promise.reject(err);
        }
    }

    async get(path: string) {
        try {
            const cur = root.child(path);
            // let cur = storage.ref(path);
            const url = await cur.getDownloadURL();
            const res = await axios.get(url);
            return res.data;
        } catch (err) {
            return Promise.reject(err);
        }
    }

    async getUrl(path: string) {
        try {
            const cur = root.child(path);
            return await cur.getDownloadURL();
        } catch (err) {
            return Promise.reject(err);
        }
    }


    // To be added: delete, update/delete metadata
}