import { useState, useEffect, useRef } from "react";
import boardwalk from "boardwalk-fb/src";
import { Board, BoardData } from "boardwalk-fb/src/boards";
import { useIsMountedRef } from "./utils";

export const useBoards = (user) => {
  const cachedBoards = useRef<BoardData[]>(undefined);
  const [boards, setBoards] = useState<BoardData[]>(cachedBoards.current);
  const mountedRef = useIsMountedRef();

  useEffect(() => {
    if (user) {
      //TODO: Right now we just pretend everyone has access to all boards. THIS NEEDS TO BE FIXED.
      // We need to use user.boards here and add .boards to user class
      // Here we should be listening to a query for a user's boards
      const unsub = user.boards.listen((boards: BoardData[]) => {
        cachedBoards.current = boards;

        if (mountedRef) {
          setBoards(cachedBoards.current);
        }
      });

      return unsub;
    }
  }, [user?.ref?.id]);

  return boards;
};

/** Note that this is cached such that this hook can only be used for one board at a time.   */
/** Above ^ After moving cachedBoard and cachedBoardData inside, I don't think this is the case anymore? @ Jay what do you think? */
export const useBoard = (id: string): [Board, BoardData] => {
  const cachedBoard = useRef<Board>(undefined);
  const cachedBoardData = useRef<BoardData>(undefined);
  const [board, setBoard] = useState<Board>(cachedBoard.current);
  const [boardData, setBoardData] = useState<BoardData>(cachedBoardData.current);
  const mountedRef = useIsMountedRef();

  useEffect(() => {
    cachedBoard.current = boardwalk.boards.board(id);
    if (mountedRef) {
      setBoard(cachedBoard.current);
    }
  }, [id]);

  useEffect(() => {
    if (board) {
      const cancelListener = board.listen((boardData) => {
        cachedBoardData.current = boardData as BoardData;
        if (mountedRef) {
          setBoardData(cachedBoardData.current);
        }
      });
      return cancelListener;
    }
  }, [board?.ref?.id]);

  return [board, boardData];
};
