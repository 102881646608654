const firebaseConfig = {
    apiKey: process.env.FB_API_KEY || "empty-key",
    authDomain: process.env.FB_AUTH_DOMAIN,
    databaseURL: process.env.FB_DB_URL || "http://localhost:9000/",
    projectId: process.env.FB_PROJECT_ID || "boardwalk-fb-test",
    storageBucket: process.env.FB_STORAGE_BUCKET || "boardwalk-fb-test-bucket",
    messagingSenderId: process.env.FB_MSG_ID,
    appId: process.env.FB_APP_ID,
    measurementId: process.env.FB_MEASUREMENT_ID
}

// Firestore local port for emulator
const localFirestoreHost = "localhost:8080";

export {
    firebaseConfig,
    localFirestoreHost
};
