import { CancelListener } from "../base/gettable";
import { BaseQuery } from "../base/query"
import { BaseData } from "../base/data";

type GetAction<T> = Promise<T | T[]>;
type Data<T> = (T | T[])[];

/**
 * Query Aggregator. This is used when multiple different queries need
 * to be used in tandem, e.g. getting all boards that are either public
 * or that a user has permissions for.
 */
export class QueryAggregator<S extends BaseData, T extends BaseQuery<S>> {

    queries: T[];
    listeners: CancelListener[];
    cachedMap: Map<string, S | S[]>;
    cachedData: (S | S[])[];
    externalQueryObserver: (data: (S | S[])[]) => void;

    constructor(queries: T[]) {
        this.queries = queries;
        this.cachedMap = new Map();
        this.cachedData = [];
        this.listeners = [];
    }

    /**
     * Add new queries to the existing query list
     */
    add(queries: T[])  {
        this.queries.concat(queries);
    }

    /* External GET handling */

    /**
     * Get currently cached data from all queries.
     * Will call `get` internally if there is no cached data
     */
    fetch() {
        return this.cachedData.length > 0 ? this.cachedData : this.get();
    }

    /**
     * Get data by making new queries
     */
    get(): Promise<Data<S>> {
        const actions = this.getAggregator();
        return this.executeGet(actions);
    }

    /* Internal GET handling */

    /**
     * Aggregate the fetched data for each query
     */
    private getAggregator(): GetAction<S>[] {
        return this.queries.map(async query => {
            const queryData = await query.get();
            // Cache the data in map if we are getting it
            this.cachedMap.set(query.constructor.name, queryData);
            return queryData;
        }); 
    }

    /**
     * Return a single promise for all query data and update the cache
     * @param actions query data for individual queries
     */
    private async executeGet(actions: GetAction<S>[]): Promise<Data<S>> {
        try {
            const data = await Promise.all(actions);
            this.cachedData = data;
            return data;
        } catch (err) {
            console.log('Error executing query set', err);
        }
    }

    /* External LISTEN handling */

    /**
     * Listen to all queries aggregated together
     * @param observer callback executed whenever the query changes
     */
    listen(observer: (data: Data<S>) => void) {
        this.externalQueryObserver = observer;
        const cancelListenerActions = this.listenAggregator();
        return this.executeListen(cancelListenerActions);
    }

    /**
     * Cancel all of the existing listeners
     */
    private cancelListeners = () => {
        // Call the CancelListener for the listener on each query
        this.listeners.forEach(cancelListener => cancelListener());
    }

    /* Internal LISTEN handling */

    /**
     * Activate the query observer for each query to update total data whenever
     * changes to the query occur
     */
    private listenAggregator(): CancelListener[] {
        return this.queries.map(query => this.activateQueryObserver(query));
    }

    /**
     * Listens to a single query and connects it to the internalQueryObserver
     * to handle updates
     * @param query
     */
    private activateQueryObserver(query: T): CancelListener {
        return query.listen((data: S | S[]) => {
            this.internalQueryObserver(data, query);
        });
    }

    /**
     * Update cancel listener callbacks and pass callback to cancel all listeners
     * @param cancelListenerActions 
     */
    private executeListen(cancelListenerActions: CancelListener[]) {
        this.listeners = cancelListenerActions;
        // Return callback to cancel all the listeners
        return this.cancelListeners;
    }

    /**
     * Update the cached data and pass it on to the external query observer
     * to alert the user of a change 
     */
    private internalQueryObserver(data: S | S[], query: T): void {
        // Let's find a way to get some real id here instead of using a unique name for each query
        // This warrants some discussion
        this.cachedMap.set(query.constructor.name, data);

        // Update data array from map, removing duplicates
        // will make this more efficient in the future
        let seen = new Set(); 
        let newCacheData = new Array();
        let updatedData = [].concat(...Array.from(this.cachedMap.values())); 
        for (const entry of updatedData) {
            if (!seen.has(entry['id'])) {
                newCacheData.push(entry);
                seen.add(entry['id']);
            }
        }
        this.cachedData = newCacheData;
        // @ts-ignore
        // this.cachedData = Array.from(new Set([].concat(...Array.from(this.cachedMap.values()))))
        // Call the external query observer so user sees the update
        this.externalQueryObserver(this.cachedData);
    }
}
