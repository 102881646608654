import React, { useEffect, useState } from 'react';

import {
  ImageComponent,
  ImageComponentData,
} from "boardwalk-fb/src/boards/components/storage/image";
import { StyledImageView } from './ImageComponentView.style';
import { StorageComponentWrapper } from '../StorageComponentWrapper';

export class ImageComponentView extends StorageComponentWrapper<ImageComponent> {
  getRegularView() {
    return super.getRegularView(
      <StyledImageView src={this.state.url}/>
    );
  }

  getExpandedView() {
    return super.getExpandedView(
      <StyledImageView src={this.state.url} expanded />
    );
  }
}