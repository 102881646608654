import React, { useEffect, useState } from 'react';
import boards, { Board } from '../../../../../../api/src/boards';
import { MemberData } from 'boardwalk-fb/src/boards/members';
import boardwalk from 'boardwalk-fb/src';
import { UserData } from '../../../../../../api/src/users';
import { StyledMetaSectionItem } from '../section/StyledMetaSectionView';
import styled from 'styled-components';

interface MembersListProps {
  board: Board;
}

export const MembersList = (props: MembersListProps) => {
  const [members, setMembers] = useState<UserData[]>([]);
  useEffect(() => {
    //@ts-ignore
    const userIds: string[] = Object.keys(props.board.lastData().users || {});
    const userListeners = userIds.map(userId => boardwalk.users.user(userId).get());
    Promise.all(userListeners).then(members => setMembers(members as UserData[]));
  }, [props.board?.ref?.id]);
  return (
    <div>
      {members.map((member: MemberData) => {
        const displayName = member.name.first + " " + member.name.last;
        return (
          <StyledMemberListItem>
            {displayName}
          </StyledMemberListItem>
        )
      })}
    </div>
  );
};

export const StyledMemberListItem = styled(StyledMetaSectionItem)``