import { BaseComponent, BaseComponentData } from 'boardwalk-fb/src/boards/components/base'
import React from 'react'
import { StyledHeaderDescription, StyledHeaderTitle, StyledHeaderView } from './HeaderView.style';

export interface HeaderProps {
  component: BaseComponent<any>;
  onTitleChange: (title: string) => void;
  onDescriptionChange: (description: string) => void;
}

export const HeaderView = (props: HeaderProps) => {
  const componentData = props.component.lastData();
  return (
    <StyledHeaderView>
      <StyledHeaderTitle
        placeholder="Name"
        value={componentData.name}
        onChange={e => props.onTitleChange(e.target.value)}
        data-testid="title"
      />
      <StyledHeaderDescription
        placeholder="Description"
        value={componentData.description}
        onChange={e => props.onDescriptionChange(e.target.value)}
        data-testid="description"
      />
    </StyledHeaderView>
  );
}