import React, { useEffect, useState } from 'react';

import {
  DocumentComponent,
  DocumentComponentData,
} from "boardwalk-fb/src/boards/components/storage/document";
import { StyledDocumentView } from './DocumentComponentView.style';
import { StorageComponentWrapper } from '../StorageComponentWrapper';

export class DocumentComponentView extends StorageComponentWrapper<DocumentComponent> {
  getRegularView() {
    return super.getRegularView(
      <StyledDocumentView src={this.state.url} />
    );
  }

  getExpandedView() {
    return super.getExpandedView(
      <StyledDocumentView src={this.state.url} expanded />
    );
  }
}
