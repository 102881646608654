import React, { useEffect, useRef, useState } from "react";
import { Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";

import { AuthedRoute } from "../Auth";
import { PageNotFound } from "../PageNotFound";
import { useBoard } from "../../hooks/boards";
import { useComponents } from "../../hooks/components";
import { useOutsideClick } from "../../hooks/utils";
import { BaseComponentWrapper, ComponentContext } from '../components/base-component/BaseComponentWrapper';
import { StyledBoardView, StyledComponentGallery } from "./BoardView.style";
import { SidebarView } from "./sidebar/SidebarView";
import { MainPanelView } from "./main-panel/MainPanelView";
import { Board } from "boardwalk-fb/src/boards";
import { useCurrentUser } from "../../hooks/users";
import { ChecklistComponent } from "boardwalk-fb/src/boards/components/checklist";
import { TextComponent } from "boardwalk-fb/src/boards/components/text";
import { DrawingComponent } from "boardwalk-fb/src/boards/components/drawing";
import { LinkComponent } from "boardwalk-fb/src/boards/components/link";
import {
  BaseComponent,
  BaseComponentData,
} from "boardwalk-fb/src/boards/components/base";
import { UserData } from "boardwalk-fb/src/users";
import { OrTagFilter } from "../../components/tag-filter/tag-filter";
import { OrFilterView } from '../tag-filter/FilterView';
import { ImageComponent } from "boardwalk-fb/src/boards/components/storage/image";
import { DocumentComponent } from "boardwalk-fb/src/boards/components/storage/document";
import useKeypress from 'react-use-keypress';
import useContextMenu from "react-use-context-menu";
import { MetaView } from './meta/MetaView';

import { useLocation } from "react-router-dom";
import qs from "qs";

import TextImage from "./quick-add/images/Text.png";
import ChecklistImage from "./quick-add/images/Checklist.png";
import DocImage from "./quick-add/images/Doc.png";
import DrawImage from "./quick-add/images/Draw.png";
import LinkImage from "./quick-add/images/Link.png";
import ImageImage from "./quick-add/images/Image.png";
import { isMobile } from "react-device-detect";

export const BoardView = () => {
  let params = useParams();
  const [board, boardData] = useBoard(params.id);
  const [user, userData] = useCurrentUser();
  const components = useComponents(board);
  const componentCreatorRef = useRef(null);
  let outsideClick = useOutsideClick(componentCreatorRef);

  const [filter, setFilter] = useState(new OrTagFilter());
  const [filterUpdate, setFilterUpdate] = useState(false);
  const handleFilterChange = () => setFilterUpdate(!filterUpdate);

  const [expandedComponents, setExpandedComponents] = useState({});
  const [
    bindContextMenu,
    bindContextMenuItems,
    useContextTrigger
  ] = useContextMenu();
  const [bindContextTrigger] = useContextTrigger({});

  useKeypress("@", (e) => {
    e.preventDefault(); // Make sure the new filter doesn't add this key.
    filter.add();
    handleFilterChange();
  });

  const location = useLocation();
  const search = qs.parse(location.search, {ignoreQueryPrefix: true});
  const searchTopics = new Set(search.topics);
  const filteredComponents = components
    .filter(c => {
      return searchTopics.size === 0 || c.tags.some(tag => searchTopics.has(tag));
    })
    .filter(c => {
      return expandedComponents[c.id] === undefined;
    })

  return board ? (
    boardData ? (
      <StyledBoardView {...bindContextTrigger}>
        <nav {...bindContextMenu}>
          <div style={{background: "white", padding: 12, borderRadius: 8, border: "1px solid #ddd", boxShadow: "0px 4px 32px rgba(0, 0, 0, 0.2)"}}>
            <QuickAddViews board={board} full/>
          </div>
        </nav>
        <MetaView board={board}/>
        <MainPanelView boardData={boardData}>
          {/* <OrFilterView filter={filter} onUnderlyingChange={handleFilterChange}/> */}
          <ComponentsView
            components={filteredComponents}
            componentDataToView={(data) =>
              BaseComponentWrapper.fromComponent(data, board, ComponentContext.REGULAR, () => setExpandedComponents({...expandedComponents, [data.id]: data}))
            }
          />
        </MainPanelView>
        {
          Object.values(expandedComponents).filter(data => data).map(
            data => BaseComponentWrapper.fromComponent(
              //@ts-ignore
              data, board, ComponentContext.EXPANDED, () => setExpandedComponents({...expandedComponents, [data.id]: undefined})))
        }
        <QuickAddSidebar
            board={board}
        />
      </StyledBoardView>
    ) : (
      <p>Loading...</p>
    )
  ) : (
    <PageNotFound />
  );
};

interface ComponentsViewProps {
  components: BaseComponentData[];
  componentDataToView: (component: BaseComponentData) => React.ReactNode;
}

export const ComponentsView = (props: ComponentsViewProps) => {
  return (
    <StyledComponentGallery>
      {props.components.map((data) => props.componentDataToView(data))}
    </StyledComponentGallery>
  );
};

interface QuickAddViewProps {
  componentClass: typeof BaseComponent;
  board: Board;
  image: string;
  name: string;
  full: boolean;
}

export const QuickAddView = (props: QuickAddViewProps) => {
  const location = useLocation();
  const search = location.search;
  const topics = qs.parse(search, {ignoreQueryPrefix: true}).topics;
  const tags = topics ? [...topics] : [];
  const [user, userData] = useCurrentUser();
  return (
    <div style={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: isMobile ? 0 : 8, cursor: "pointer"}}>
      <img
        onClick={() => {
          //@ts-ignore
          (props.board as Board).components.add({
            ...props.componentClass.getDefaultData(userData, props.board.ref.id),
            tags
          });
        }}
        data-testid="button-add"
        style = {{
          height: 30,
          width: 30,
        }}
        src={props.image}
      />
      {props.full && (
        <div style={{fontFamily: "Sen", fontWeight: 500, marginLeft: 8}}>{props.name}</div>
      )}
    </div>
  );
};

export interface QuickAddSidebarProps {
    board: Board;
}

export const QuickAddSidebar = (props: QuickAddSidebarProps) => {
  const [isFull, setIsFull] = useState(false);
  return (
      <SidebarView background={props.board.lastData().secondaryColor}>
        <QuickAddViews board={props.board} horizontal={isMobile} full={isFull}/>
        {!isMobile && <div className="material-icons" style={{color: "black", marginBottom: 20, cursor: "pointer"}} onClick={() => setIsFull(!isFull)}>{isFull ? "chevron_right" : "chevron_left"}</div>}
      </SidebarView>
  )
}

export const QuickAddViews = ({board, full = false, horizontal = false}) => {
  return (
    <div style={{display: "flex", flexDirection: horizontal ? "row" : "column", justifyContent: horizontal ? "space-around" : "start", flexGrow: 1}}>
      <QuickAddView board={board} componentClass={TextComponent as typeof BaseComponent} image={TextImage} name="Text" full={full}/>
      <QuickAddView board={board} componentClass={DocumentComponent as typeof BaseComponent} image={DocImage} name="Doc" full={full}/>
      <QuickAddView board={board} componentClass={ImageComponent as typeof BaseComponent} image={ImageImage} name="Image" full={full}/>
      <QuickAddView board={board} componentClass={DrawingComponent as typeof BaseComponent} image={DrawImage} name="Drawing" full={full}/>
      <QuickAddView board={board} componentClass={LinkComponent as typeof BaseComponent} image={LinkImage} name="Link" full={full}/>
      <QuickAddView board={board} componentClass={ChecklistComponent as typeof BaseComponent} image={ChecklistImage} name="Checklist" full={full}/>
    </div>
  );
}