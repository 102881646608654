import React from 'react';

import {
  LinkComponent,
  LinkComponentData,
} from "boardwalk-fb/src/boards/components/link";
import { BaseComponentContentProps, BaseComponentWrapper, ComponentContent, ComponentContext } from '../base-component/BaseComponentWrapper';
import { StyledLink, StyledLinkEntry } from './LinkComponentView.style';

export class LinkComponentView extends BaseComponentWrapper<LinkComponent> {
  getRegularView() {
    return super.getRegularView(
      <LinkComponentContentView
        component={this.component}
        context={ComponentContext.REGULAR}
      />
    );
  }

  getExpandedView() {
    return super.getExpandedView(
      <LinkComponentContentView
        component={this.component}
        context={ComponentContext.EXPANDED}
      />
    );
  }

  getMenuOptions() {
    return [
      ...super.getMenuOptions(),
      {label: "Clear", handler: () => this.component.update({data: {link: ""}})}
    ];
  }
}

interface LinkComponentContentProps extends BaseComponentContentProps<LinkComponent> {
  context: ComponentContext;
}

export const LinkComponentContentView = (props: LinkComponentContentProps) => {
  const componentData = props.component.lastData();
  const {link} = componentData.data;
  if (link) {
    return <ContextAwareLinkView link={link} context={props.context}/>;
  } else {
    return (
      <>
        <StyledLinkEntry
          placeholder="Paste a link..."
          onChange={e => props.component.update({data: {link: e.target.value}})}
        />
      </>
    )
  }
}

interface ContextAwareLinkViewProps {
  link: string;
  context: ComponentContext;
}

export const ContextAwareLinkView = (props: ContextAwareLinkViewProps) => {
  let link = props.link;
  const url = new URL(props.link);
  let transformer = LinkTransformers[url.host];

  if (!transformer) {
    transformer = LinkTransformers.default;
  }

  if (!transformer[props.context] && !([".png", ".jpg", ".gif"].some(ext => props.link.includes(ext)))) {
    return <StyledLink href={link} target="_blank">{link}</StyledLink>
  } else {
    const transformedLink = transformer.transform(url);
    return <IFrameLinkView src={transformedLink}/>
  }
}

export const IFrameLinkView = ({src}) => {
  return (
    <iframe src={src} style={{height: "100%", border: "1px solid #ddd"}}/>
  )
}

const YoutubeLinkTransformer = {
  transform: (url: URL) => {
    let videoId;
    // Check if a shortlink or normal.
    if (url.host.match(/youtu\.be/)) {
      videoId = url.pathname.split("/")[1];
    } else {
      videoId = url.searchParams.get("v");
    }
    return `https://youtube.com/embed/${videoId}`;
  },
  [ComponentContext.REGULAR]: true,
  [ComponentContext.EXPANDED]: true
}

const YoutubeLinkTransformers = {
  "youtu.be": YoutubeLinkTransformer,
  "www.youtu.be": YoutubeLinkTransformer,
  "www.youtube.com": YoutubeLinkTransformer,
  "youtube.com": YoutubeLinkTransformer,
  "m.youtube.com": YoutubeLinkTransformer
}

const LinkTransformers = {
  ...YoutubeLinkTransformers,
  "default": {
    transform: url => url,
    [ComponentContext.EXPANDED]: true
  }
}
