import { UserData } from "../../../users";
import { Components } from "../base";
import { DocumentComponentData } from "./document";
import { StorageComponentData, StorageComponent } from "./storage";

export interface ImageComponentData extends StorageComponentData {
  label?: string;
}

export class ImageComponent extends StorageComponent<DocumentComponentData> {
  constructor(id: string, components: Components<DocumentComponentData>) {
    super(id, components, "images");
  }

  static getDefaultData(userData: UserData, parent: string, data: any = {}): Omit<ImageComponentData, "id" | "ref"> {
    return {
      ...super.getDefaultData(userData, parent),
      componentType: "image",
      data: {
        path: "",
        ...data
      }
    };
  }

  getAcceptableTypes(){
    return "image/*";
  }
}