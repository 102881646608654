import { StorageComponentData, StorageComponent } from "./storage";
import { Components } from "../base";
import { UserData } from "../../../users";

export interface DocumentComponentData extends StorageComponentData {}

export class DocumentComponent extends StorageComponent<DocumentComponentData> {
  constructor(id: string, components: Components<DocumentComponentData>) {
    super(id, components, "documents");
  }

  static getDefaultData(userData: UserData, parent: string, data: any = {}): Omit<DocumentComponentData, "id" | "ref"> {
    return {
      ...super.getDefaultData(userData, parent),
      componentType: "document",
      data: {
        path: "",
        ...data
      }
    };
  }

  getAcceptableTypes() {
    return ".pdf";
  }
}