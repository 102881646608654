import { Board } from "..";
import { BaseCollection, BaseData, BaseDocument } from "../../base";
import { UserData } from "../../users";
import { getKVPair } from "../../utils/typing";


export interface BaseComponentData extends BaseData {
  name: string;
  description: string;
  componentType: string;
  tags: string[];
  editable: boolean;
  parent?: string;
  owner: {
    name: {
      first: string;
      last: string;
    };
  };
  /* make this data: T, use BaseComponentData<T> then have below interfaces just restrict the form of `data`
    Data object should hold any non-generic data for a component, can be split into
    data: {
        meta: {}
        ... other main data, e.g. for storage:
        storeLink: "images/thisisajpg.jpg"
    }
 
    ** Even though we can store metadata in firebase storage, I think it makes sense to keep it here
    instead because we will need to fetch this link anyways before we can fetch the actual file from storage.
    */
  data: { [key: string]: any; };
}

export class Components<T extends BaseComponentData> extends BaseCollection<T> {
  board: Board;
  constructor(board: Board) {
    super(board.ref.collection("components"));
    this.board = board;
  }

  // Adding a component should return a new component of the correct type
  async add(data: Omit<T, "id" | "ref">) {
    let ref = await super.add(data);
    return this.component(ref.id);
  }

  // How to make this new Component() work by getting the type from object?
  // ?? https://dev.to/_gdelgado/type-safe-error-handling-in-typescript-1p4n
  component(id: string): BaseComponent<T> | any {
    const componentRef = this.ref.doc(id);
    return componentRef.get().then((doc) => {
      if (doc.exists) {
        const type = doc.data().componentType;
        return getKVPair(componentTypeMap, type)(id, this);
      }
      // TODO: raise better exceptions
      throw new Error(`No doc exists with given ID: ${id}`);
    });
  }
}

export class BaseComponent<T extends BaseComponentData> extends BaseDocument<
  T
> {
  board: Board;
  constructor(id: string, components: Components<T>) {
    super(components.ref.doc(id));
    this.board = components.board;
  }

  static getDefaultData(userData: UserData, parent: string = ""): Omit<BaseComponentData, "data" | "id" | "componentType" | "ref"> {
    return {
      name: "",
      description: "",
      tags: [],
      editable: true,
      parent,
      owner: {
        name: userData.name
      }
    }
  }
}

import { componentTypeMap } from ".";