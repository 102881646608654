import {GettablePlural} from "./gettable";
import {BaseData} from "./data";

/** A Query is similar to a Collection, except that it does not support 'add' operations. */
export class BaseQuery<T extends BaseData> extends GettablePlural<T> {
  ref: firebase.firestore.Query;
  
  constructor(ref: firebase.firestore.Query) {
    super();
    this.ref = ref;
  }
}
