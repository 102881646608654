import React from 'react';

import {
  DrawingComponent,
  DrawingComponentData,
} from "boardwalk-fb/src/boards/components/drawing";
import { BaseComponentContentProps, BaseComponentWrapper } from '../base-component/BaseComponentWrapper';
import { StyledDrawingArea, StyledDrawingComponentView } from './DrawingComponentView.style';

export class DrawingComponentView extends BaseComponentWrapper<DrawingComponent> {
  getRegularView() {
    return super.getRegularView(
      <DrawingComponentContentView
        component={this.component}
      />
    );
  }

  getExpandedView() {
    return super.getExpandedView(
      <DrawingComponentContentView
        component={this.component}
        expanded
      />
    );
  }
}

interface DrawingComponentContentViewProps extends BaseComponentContentProps<DrawingComponent> {
  expanded?: boolean;
}

export const DrawingComponentContentView = (props: DrawingComponentContentViewProps) => {
  const data = props.component.lastData();
  let canvas;
  const save = () => {
    props.component.updateDrawing(canvas.getSaveData());
  }
  return (
    <>
      <StyledDrawingComponentView
        onMouseUp={() => props.component.updateDrawing(canvas.getSaveData())}
        expanded={props.expanded}
      >
        <StyledDrawingArea
          canvasWidth="100%"
          canvasHeight="100%"
          hideInterface
          saveData={data.data.drawing}
          immediateLoading
          brushRadius={4}
          lazyRadius={4}
          hideGrid
          ref={canvasRef => canvas = canvasRef}
        />
      </StyledDrawingComponentView>
      <button onClick={() => {
        canvas.undo();
        save();
      }}>Undo</button>

      <button onClick={() => {
        canvas.clear();
        save();
      }}>Clear</button>
    </>
  )
}