import React, { useRef, useState } from 'react'
import { Board } from '../../../../../api/src/boards'
import { StyledMetaView } from './StyledMetaView'
import styled from 'styled-components';
import { MetaSectionView } from './section/MetaSectionView';
import { MembersList } from './members/MembersList';
import { PermissionSelector } from './members/Permissions';
import { MemberPanel } from './members/MemberControl';
import { TopicsList } from './topics/TopicsList';
import { useBoards } from '../../../hooks/boards';
import { useCurrentUser } from '../../../hooks/users';
import { Link } from "react-router-dom";
import { useOutsideClick } from '../../../hooks/utils';
import {isMobile} from "react-device-detect";

export interface MetaViewProps {
  board: Board;
}

export const MetaView = (props: MetaViewProps) => {
  const [showSettings, setShowSettings] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [user, userData] = useCurrentUser();
  
  const isMember = props.board.lastData() && userData && props.board.lastData().users[userData.id] !== undefined;

  return (
    <StyledMetaView showContent={showContent} background={props.board.lastData().primaryColor}>
      {isMobile && <div className="material-icons" style={{color: "white", marginRight: 16}} onClick={() => setShowContent(true)}>menu</div>}
      <CurrentBoardSelector board={props.board}/>
      <div className="content">
        {isMobile && <div className="material-icons" style={{color: "white"}} onClick={() => setShowContent(false)}>arrow_back</div>}
        <MetaSectionView title="members">
          <MembersList board={props.board}/>
        </MetaSectionView>
        <MetaSectionView title="topics">
          <TopicsList board={props.board}/>
        </MetaSectionView>
        <div style={{height: 0, flexGrow: 1}}/>
        <Link to="/app/boards" style={{fontFamily: "Sen", fontWeight: 700, color: "white", cursor: "pointer", marginBottom: 20}}>All Boards</Link>
        {isMember && <div onClick={() => setShowSettings(true)} style={{fontFamily: "Sen", fontWeight: 700, color: "white", cursor: "pointer"}}>Settings</div>}
        {showSettings && isMember && props.board.lastData().users[userData.id] && <Settings board={props.board} onClose={() => setShowSettings(false)}/>}
      </div>
    </StyledMetaView>
  )
}

interface CurrentBoardSelectorProps {
  board: Board;
}
export const CurrentBoardSelector = (props: CurrentBoardSelectorProps) => {
  const {name} = props.board.lastData();
  const [showBoardSelector, setShowBoardSelector] = useState(false);

  return (
    <div>
      {showBoardSelector && <OtherBoardsView onClose={() => setShowBoardSelector(false)}/>}
      <StyledBoardName onClick={() => setShowBoardSelector(true)}>
        {name}
      </StyledBoardName>
    </div>
  )
}

const StyledPopover = styled.div`
  position: absolute;
  background: white;
  border-radius: 16px;
  padding: 16px;
  width: 500px;
  box-shadow: 0px 8px 16px rgba(20, 20, 20, 0.2);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

interface PopoverProps {
  children: React.ReactNode;
  onClose: () => void;
  style: any
}

const Popover = (props: PopoverProps) => {
  const ref = useRef(null);
  const clicked = useOutsideClick(ref);
  if (clicked) {
    props.onClose();
  }

  return (
    <StyledPopover ref={ref} {...props}>
      {props.children}
    </StyledPopover>
  )
}

interface OtherBoardsViewProps {
  onClose: () => void;
}

const OtherBoardsView = (props: OtherBoardsViewProps) => {
  const [user] = useCurrentUser();

  const [search, setSearch] = useState("");
  const otherBoards = useBoards(user);

  const displayedOtherBoards = (otherBoards || []).filter(data => data.name.includes(search) || search.includes(data.name));

  return (
    <Popover {...props} style={{top: 40, left: 20, zIndex: 200}}>
      <OtherBoardsSearch value={search} onChange={e => setSearch(e.target.value)} autoFocus/>
      {displayedOtherBoards.map(board => <StyledOtherBoardLink to={`/app/boards/${board.id}`}>{board.name}</StyledOtherBoardLink>)}
    </Popover>
  )
}

const OtherBoardsSearch = styled.input`
  width: 100%;
  border: none;
  padding: 10px;
  background: rgba(0, 0, 0, 0.06);
  font-family: ${props => props.theme.brand.primaryFont.family};
`;

const StyledOtherBoardLink = styled(Link)`
  padding: 10px;
  margin: 8px;
  white-space: nowrap;
  &:hover {
    background: rgba(20, 20, 20, 0.06);
  }
  border-radius: 8px;
`;


const StyledBoardName = styled.div`
  font-family: ${props => props.theme.brand.primaryFont.family};
  color: white;
  font-size: 20px;
  font-weight: ${props => props.theme.brand.primaryFont.boldWeight};
  cursor: pointer;
  text-shadow: 0px 2px 1px rgba(0, 0, 0, .2);
`;

interface SettingsProps {
  board: Board;
  onClose: () => void;
}

const colors = ["#0A0868", "#1299F3", "#3DF5B1", "#F0249E", "#F09724", "#152d40", "#f04c24", "#24dbf0", "#9124f0", "#f02477", "#323b42", "#2d2344", "#d22f2f", "#609e3c"];
const Settings = (props: SettingsProps) => {
  return (
    <Popover {...props} style={{bottom: 40, left: 20, display: 'block'}}>
      <input
        style={{
          width: "100%",
          border: "none",
          padding: "10px",
          background: "rgba(0, 0, 0, 0.06)",
          fontFamily: "Sen",
          marginBottom: "8px",
          boxSizing: "border-box"
        }}
        value={props.board.lastData().name}
        onChange={e => props.board.update({name: e.target.value})}
      />

      <PermissionSelector board={props.board} />
      <MemberPanel board={props.board} />

      <div style={{fontFamily: "Sen", marginBottom: 8}}>
        Primary Color
      </div>

      <ColorSelector
        colors={colors}
        value={props.board.lastData().primaryColor}
        onChange={primaryColor => props.board.update({primaryColor})}
      />

      <div style={{fontFamily: "Sen", marginBottom: 8}}>
        Secondary Color
      </div>

      <ColorSelector
        colors={colors}
        value={props.board.lastData().secondaryColor}
        onChange={secondaryColor => props.board.update({secondaryColor})}
        lastChild={true}
      />
    </Popover>
  )
}

interface ColorSelectorProps {
  colors: string[];
  value: string;
  onChange: (value: string) => void;
  lastChild?: boolean;
}

const ColorSelector = (props: ColorSelectorProps) => {
  return (
    <div style={{
            display: "flex",
            width: "100%",
            overflow: "auto",
            marginBottom: !!props.lastChild ? 0 : 8
          }}>
      {props.colors.map(color => {
        return (
          <div
            style={{
              width: 30, height: 30,
              borderRadius: 15, border: props.value === color ? "4px solid black" : "none",
              background: color,
              marginRight: 8,
              boxSizing: "border-box",
              flexShrink: 0
            }}
            onClick={() => props.onChange(color)}
          />
        )
      })}
    </div>
  )
}
