import {Gettable} from "./gettable";
import {BaseData} from "./data";
import { BaseComponent } from "../boards/components/base";

const DOCUMENT_CACHE: {[key: string]: BaseData} = {};

/** 
 * A document is a single entity from firebase.
 * It supports CRUD + listen.
*/
export class BaseDocument<T extends BaseData> extends Gettable<T> {
  ref: firebase.firestore.DocumentReference;
  constructor(ref: firebase.firestore.DocumentReference) {
    super();
    this.ref = ref;
  }

  snapshotToData(snapshot: firebase.firestore.DocumentSnapshot): T {
    const data = { id: snapshot.id, ref: snapshot.ref, ...snapshot.data() } as T;
    this.updateCache(data);
    return data;
  }

  /** When updating, not all fields have to be 're-passed' in. */
  async update(data: Partial<T>): Promise<void> {
    return this.ref.update(data);
  }

  /** Delete the document permanantly. */
  async delete(): Promise<void> {
    return this.ref.delete();
  }

  private updateCache(data: T) {
    if (this.ref && this.ref.id) {
      DOCUMENT_CACHE[this.ref.id] = data;
    }
  }

  public lastData(): T {
    return DOCUMENT_CACHE[this.ref.id] as T;
  }
}
