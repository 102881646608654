import React, { useEffect, useState } from 'react';

import {
  ChecklistComponent,
  ChecklistItemComponent,
  ChecklistItemComponentData,
} from "boardwalk-fb/src/boards/components/checklist";
import { BaseComponentWrapper, BaseComponentContentProps, getDraggedComponent } from '../base-component/BaseComponentWrapper';
import { StyledChecklistBox, StyledChecklistItem, StyledChecklistText } from './ChecklistComponentView.style';
import { EmbeddedComponentContainerView } from '../base-component/views/embedded/container/EmbeddedComponentContainerView';
import { useCurrentUser } from '../../../hooks/users';
import boardwalk from 'boardwalk-fb/src';

export class ChecklistComponentView extends BaseComponentWrapper<ChecklistComponent> {
  getRegularView() {
    return super.getRegularView(
      <ChecklistComponentContentView
        component={this.component}
      />
    );
  }

  getExpandedView() {
    return super.getExpandedView(
      <ChecklistComponentContentView
        component={this.component}
      />
    );
  }

  getMenuOptions() {
    return [
      ...super.getMenuOptions(),
      {label: "Add Item", handler: () => this.component.addItem(boardwalk.users.user(boardwalk.auth.currentUser.uid).lastData())}
    ]
  }

}

export class ChecklistItemComponentView extends BaseComponentWrapper<ChecklistItemComponent> {
  getEmbeddedView() {
    const componentData = this.component.lastData();
    return super.getEmbeddedView(
      <StyledChecklistBox
        type="checkbox"
        checked={componentData.data?.completed}
        onChange={e => this.component.update({data: {completed: e.target.checked}})}
        data-testid="checklist-box"
      />
    );
  }

  getMenuOptions() {
    const board = this.component.board;
    const checklistsOnBoard = board.components.lastData() || [];

    return [
      ...super.getMenuOptions(),
      ...checklistsOnBoard.filter(c => c.ref.id !== this.component.lastData().parent).filter(c => c.name !== undefined).map(c => ({
        label: `Move to ${c.name}`,
        handler: () => this.component.update({parent: c.ref.id})
      }))
    ]
  }
}

export const ChecklistComponentContentView = (props: BaseComponentContentProps<ChecklistComponent>) => {
  return (
    <>
      <ChecklistItemsWrapper
        checklist={props.component}
        data-testid="items"
      />
    </>
  )
}

export interface ChecklistItemsWrapperProps {
  checklist: ChecklistComponent;
}

export const ChecklistItemsWrapper = (props: ChecklistItemsWrapperProps) => {
  const [items, setItems] = useState<ChecklistItemComponentData[]>([]);
  const [user, userData] = useCurrentUser();
  useEffect(() => {
    return props.checklist.items.listen((newItems: ChecklistItemComponentData[]) => setItems(newItems));
  }, []);

  return (
    <EmbeddedComponentContainerView
      components={items}
      board={props.checklist.board}
      data-testid="checklist-items"
      onDragOver={e => {e.preventDefault(); e.stopPropagation();}}
      onDrop={e => {
        e.preventDefault();
        e.stopPropagation();
        if (e.kind === "component") {
          const component = getDraggedComponent();
          if (component.lastData().componentType === "checklist-item") {
            component.update({parent: props.checklist.ref.id});
          }
        } else if (e.dataTransfer.items) {
          console.log("here");
          for (let item of e.dataTransfer.items) {
            if (item.kind === "string" && item.type == "text/plain") {
              item.getAsString(async content => {
                for (let s of content.split("\n").filter(s => s !== "")) {
                  const component = await props.checklist.addItem(userData);
                  component.update({name: s});
                }
              });
            }
          };
        }
      }}
    />
  );
}