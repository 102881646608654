import React, { useReducer, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Boardwalk from "boardwalk-fb/src";
import { creationReducer } from "../hooks/reducers";
import { useCurrentUser } from "../hooks/users";

const initialState = {
    name: '',
    description: '',
    tags: '',
    error: '',
    permission: 'public'
}

const BoardCreator = ({ reference }) => {
    const history = useHistory();
    const [user, userData, userId] = useCurrentUser();
    const [state, dispatch] = useReducer(creationReducer, initialState);
    const { name, description, tags, error, permission } = state;

    const onInputChange = (event) => {
        dispatch({
            type: 'field',
            fieldName: event.currentTarget.name,
            payload: event.currentTarget.value
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const splitTags = tags.split(', ');
        Boardwalk.boards.add({
            name: name,
            description: description,
            tags: splitTags,
            users: { // Should members be a collection? Idk...
                [userId]: {
                    permission: 'owner',
                    name: {
                        first: userData.name.first,
                        last: userData.name.last
                    }
                }
            },
            public: {
                read: permission == '1' || permission == '2',
                update: permission == '2',
            }
        }).then(() => {
            history.push('/app/boards');
        });
    }

    return (
        <div ref={reference} style={{background: "white", padding: 24, position: "fixed", transform: "translate(-50%, -50%)", left: "50%", top: "50%", borderRadius: 16, boxShadow: "0px 8px 32px rgba(20, 20, 20, 0.1)", width: 300}}>
            <div style={{fontFamily: "Sen", color: "black"}}>New Board</div>
            <form style={{display: "flex", flexDirection: "column"}}>
                {error && <p>{error}</p>}
                <input
                    name="name"
                    placeholder="Name"
                    value={name}
                    onChange={onInputChange}
                    style={{padding: 8, marginTop: 8, background: "#f5f5f5", border: "1px solid #ddd", borderRadius: 4, fontFamily: "Sen"}}
                />
                <input
                    name="description"
                    placeholder="Description"
                    value={description}
                    onChange={onInputChange}
                    style={{padding: 8, marginTop: 8, background: "#f5f5f5", border: "1px solid #ddd", borderRadius: 4, fontFamily: "Sen"}}
                />
                {/* For now, tags has to be a comma separated list  */}
                {/* <input
                    name="tags"
                    placeholder="New tag"
                    value={tags}
                    onChange={onInputChange}
                /> */}
                <select 
                    name="permission"
                    value={permission}
                    onChange={onInputChange}
                    style={{padding: 8, marginTop: 8, background: "#f5f5f5", border:"1px solid #ddd",  borderRadius: 4, fontFamily: "Sen"}}
                >
                    <option value="0">Private</option>
                    <option value="1">Anyone can view</option>
                    <option value="2">Anyone can view and edit</option>
                </select>
                <button
                    onClick={handleSubmit}
                    style={{padding: 8, marginTop: 16, border: "1px solid #ddd", borderRadius: 4, fontFamily: "Sen", background: "none"}}
                >Add Board</button>
            </form>
        </div>
    )
}

export default BoardCreator;