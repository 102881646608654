import { BaseComponent, BaseComponentData } from "boardwalk-fb/src/boards/components/base";
import { StorageComponent } from "boardwalk-fb/src/boards/components/storage/storage";
import React from "react";
import { useEffect, useState } from "react";
import { BaseComponentContentProps, BaseComponentState, BaseComponentWrapper, ComponentContent } from "../base-component/BaseComponentWrapper";

interface StorageComponentState extends BaseComponentState {
  url: string;
}

export class StorageComponentWrapper<T extends StorageComponent<any>> extends BaseComponentWrapper<T, StorageComponentState> {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      url: undefined
    }
  }

  componentDidMountDerived() {
    super.componentDidMountDerived();
    const {path} = this.component.lastData().data;
    if (path !== "") {
      this.component.fileStore.getUrl(path).then(url => this.setState({url}));
    }
  }

  getRegularView(content: ComponentContent<T>) {
    const { path } = this.component.lastData().data;
    if (this.state.url || path === "") {
      return super.getRegularView(
        <StorageComponentViewContent
          content={content}
          component={this.component}
        />
      );
    } else {
      return super.getRegularView(<span>Loading...</span>);
    }
  }

  getExpandedView(content: ComponentContent<T>) {
    const { path } = this.component.lastData().data;
    if (this.state.url || path === "") {
      return super.getExpandedView(
        <StorageComponentViewContent
          content={content}
          component={this.component}
        />
      );
    } else {
      return super.getExpandedView(<span>Loading...</span>);
    }
  }

  getMenuOptions() {
    const { path } = this.component.lastData().data;

    return [
      ...super.getMenuOptions(),
      path ? {
        label: "Replace",
        dangerous: true,
        handler: async () => {
          this.component.fileStore.ref.root.child(path).delete();
          this.component.update({data: {path: ""}});
        }
      }: undefined
    ]
  }
}

export const StorageComponentViewContent = (props: BaseComponentContentProps<StorageComponent<any>>) => {
  const existingPath = props.component.lastData().data.path;
  const hasExistingPath = existingPath !== "";
  const [uploadProgress, setUploadProgress] = useState<number>(undefined);

  if (uploadProgress !== undefined) {
    return <span>Uploading... ({(100 * uploadProgress).toFixed(0)}%)</span>;
  }

  return (
    <>
      {hasExistingPath ? props.content : (
        <>
          <input
            type="file"
            accept={props.component.getAcceptableTypes()}
            onChange={async e => {
              const file = e.target.files.item(0);
              const path = await props.component.fileStore.add(file, setUploadProgress);
              await props.component.update({data: {path}});
              setUploadProgress(undefined);
            }}
          />
        </>
      )}
    </>
  );
}