import { BaseComponent, BaseComponentData } from 'boardwalk-fb/src/boards/components/base';
import React from 'react';
import { BaseComponentViewProps } from '../base';
import { StyledEmbeddedComponentView, StyledEmbeddedComponentViewContent, StyledEmbeddedComponentViewHeader, StyledTitleInput } from './EmbeddedComponentView.style';

export const EmbeddedComponentView = <C extends BaseComponent<any>>(props: BaseComponentViewProps<C>) => {
  const data = props.component.lastData() as BaseComponentData;
  return (
    <StyledEmbeddedComponentView>
      <StyledEmbeddedComponentViewHeader>
        <StyledTitleInput value={data.name} placeholder="Name" onChange={e => props.component.update({name: e.target.value})} />
      </StyledEmbeddedComponentViewHeader>
      {props.content && (
        <StyledEmbeddedComponentViewContent>
          {props.content}
        </StyledEmbeddedComponentViewContent>
      )}
    </StyledEmbeddedComponentView>
  )
}