export interface Tags {
  [key: string]: boolean;
}

export interface TagFilter {
  matches(tags: Tags): boolean;
}

export class SingleTagFilter implements TagFilter {
  tag: string;

  constructor(tag: string = "") {
    this.tag = tag;
  }

  matches(tags: Tags): boolean {
    if(tags[this.tag]) return true;
    return Object.keys(tags).some(key => this.tag.includes(key) || key.includes(this.tag));
  }
}

export abstract class CompoundTagFilter<T> implements TagFilter {
  filters: T[];

  constructor(filters: T[] = []) {
    this.filters = filters;
  }

  getFilters(): T[] {
    return this.filters;
  }

  addFilter(filter: T) {
    this.filters.push(filter);
  }

  removeFilter(index: number) {
    this.filters.splice(index, 1);
  }

  abstract matches(tags: Tags): boolean;
  abstract add(): void;
}

export class AndTagFilter extends CompoundTagFilter<SingleTagFilter> {
  matches(tags: Tags): boolean {
    for (let filter of this.filters) {
      if (!filter.matches(tags)) {
        return false;
      }
    }
    return true;
  }
  add() {
    this.addFilter(new SingleTagFilter());
  }
}

export class OrTagFilter extends CompoundTagFilter<AndTagFilter> {
  matches(tags: Tags): boolean {
    return this.filters.length === 0 || this.filters.some((filter) => filter.matches(tags));
  }
  add() {
    this.addFilter(new AndTagFilter([new SingleTagFilter()]));
  }
}
